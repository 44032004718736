<div class="container mx-auto relative mt-0 lg:mt-20" id="news-overview">
  <div class="flex items-center flex-wrap mb-5" *ngIf="educationTerms && educationTerms.length > 0">
    <strong class="mr-10">Onderwijs</strong>
    <span *ngFor="let term of educationTerms; let index = index">
      <input type="checkbox" id="education-{{index}}" [(ngModel)]="educationTerms[index].selected"
             (ngModelChange)="filterChanged($event)" name="education-term-filter[]" [value]="term.id" class="sr-only">
      <label for="education-{{index}}" href="#" class="rounded-2xl bg-[#f7f7f7] py-3 px-4 mx-2 inline-block">
        {{ term.name }}
      </label>
    </span>
  </div>
  <div class="flex items-center flex-wrap mb-5" *ngIf="educationTerms && subjectTerms.length > 0">
    <strong class="mr-10">Onderwerp</strong>
    <span *ngFor="let term of subjectTerms; let index = index">
      <input type="checkbox" id="subject-{{index}}" [(ngModel)]="subjectTerms[index].selected"
             (ngModelChange)="filterChanged($event)" name="subject-term-filter[]" [value]="term.id" class="sr-only">
      <label for="subject-{{index}}" href="#" class="rounded-2xl bg-[#f7f7f7] py-3 px-4 mx-2 mb-2 inline-block">
        {{ term.name }}
      </label>
    </span>
  </div>
  <ng-container *ngIf="isLoading$|async else doneLoading">
    <p>Laden...</p>
  </ng-container>
  <ng-template #doneLoading>
    <p *ngIf="!total">Er zijn <strong>0</strong> resultaten voor jouw zoekopdracht</p>
    <p *ngIf="total && !compact && !limit ">Er zijn <strong>{{ total }}</strong> resultaten voor jouw zoekopdracht</p>
  </ng-template>
  <div class="flex items-center absolute lg:right-24 sm:my-10 lg:bottom-0" *ngIf="!compact && !limit">
    <span class="font-bold">Sorteer&nbsp;&nbsp;&nbsp;</span>
    <select>
      <option value="created_desc">Publicatiedatum nieuw > oud</option>
      <option value="created_asc">Publicatiedatum oud > nieuw</option>
    </select>
  </div>
</div>
<div class="container mx-auto mt-28 mb-20" *ngIf="newsNodes && !compact">
  <ng-container *ngIf="isLoading$|async">
    <div class="loading-overlay">
      <div class="la-ball-clip-rotate">
        <div></div>
      </div>
    </div>
  </ng-container>
  <div class="grid grid-cols-12 gap-10">
    <div class="lg:col-span-4 md:col-span-6 col-span-12 flex"
         *ngFor="let node of newsNodes | paginate: {itemsPerPage: itemsPerPage, totalItems: total, currentPage: p}">
      <app-card-with-image [cardData]="node"></app-card-with-image>
    </div>
  </div>
</div>

<div *ngIf="newsNodes && compact">
  <div class="grid grid-cols-6 gap-10 mb-10 lg:mb-40">
    <div class="col-span-6 lg:col-span-4 flex">
      <app-card-with-image [cardData]="newsNodes[0]" [highlighted]="true" class="flex w-full"></app-card-with-image>
    </div>
    <div class="col-span-6 md:col-span-3 lg:col-span-2" *ngFor="let node of newsNodes | slice: 1 :4">
      <app-card-with-image [cardData]="node" [highlighted]="false"></app-card-with-image>
    </div>
    <div class="col-span-6 md:col-span-3 lg:col-span-2">
      <h3 class="text-2xl lg:text-3xl font-normal tracking-[0.5px] pb-8 pt-10">Meer actualiteiten</h3>
      <ul class="border-y-2 border-tertiary-default">
        <li *ngFor="let node of newsNodes | slice: 4">
          <a [routerLink]="node.path" class="flex items-center py-7 border-b-2 border-tertiary-default hover:underline focus:outline font-bold">
            <svg width="48" height="48" viewBox="0 0 48 48" style="width: 48px; height: 48px;" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-4 icon-size">
              <path d="M24 16L21.2 18.8L24.4 22L16 22L16 26L24.4 26L21.2 29.2L24 32L32 24L24 16ZM24 4C26.7667 4 29.3667 4.525 31.8 5.575C34.2333 6.625 36.35 8.05 38.15 9.85C39.95 11.65 41.375 13.7667 42.425 16.2C43.475 18.6333 44 21.2333 44 24C44 26.7667 43.475 29.3667 42.425 31.8C41.375 34.2333 39.95 36.35 38.15 38.15C36.35 39.95 34.2333 41.375 31.8 42.425C29.3667 43.475 26.7667 44 24 44C21.2333 44 18.6333 43.475 16.2 42.425C13.7667 41.375 11.65 39.95 9.85 38.15C8.05 36.35 6.625 34.2333 5.575 31.8C4.525 29.3667 4 26.7667 4 24C4 21.2333 4.525 18.6333 5.575 16.2C6.625 13.7667 8.05 11.65 9.85 9.85C11.65 8.05 13.7667 6.625 16.2 5.575C18.6333 4.525 21.2333 4 24 4ZM24 8C19.5333 8 15.75 9.55 12.65 12.65C9.55 15.75 8 19.5333 8 24C8 28.4667 9.55 32.25 12.65 35.35C15.75 38.45 19.5333 40 24 40C28.4667 40 32.25 38.45 35.35 35.35C38.45 32.25 40 28.4667 40 24C40 19.5333 38.45 15.75 35.35 12.65C32.25 9.55 28.4667 8 24 8Z" fill="#481249"/>
            </svg>
            {{ node.title }}
          </a>
        </li>
        <li class="py-6">
          <a routerLink="/nieuws" class="text-white bg-primary inline-flex items-center p-4 font-bold">
            <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-2">
              <path d="M11.7146 12L7.43784 7.4L8.73946 6L14.3178 12L8.73946 18L7.43784 16.6L11.7146 12Z" fill="#F8D100"/>
            </svg>
            Meer nieuws
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
  <div class="container mx-auto text-center pt-10"  *ngIf="!compact && !limit">
    <pagination-controls (pageChange)="pageChange($event)"
                         previousLabel="Vorige"
                         nextLabel="Volgende"
                         screenReaderPaginationLabel="Paginering"
                         screenReaderPageLabel="pagina"
                         screenReaderCurrentLabel="Je bent op pagina" [autoHide]="true"
    ></pagination-controls>
  </div>
