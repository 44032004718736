<div class="container mx-auto">
  <app-breadcrumbs *ngIf="breadcrumbs" [breadcrumbs]="breadcrumbs"></app-breadcrumbs>
  <h1 class="mt-4 mb-6">{{ media.name }}</h1>
  <app-text-content [content]="media.description?.processed" class="mb-6 block" *ngIf="media.description?.processed"></app-text-content>
  <div class="inline-block">
    <ng-container *ngIf="media.mediaFile" [ngTemplateOutlet]="fileDownload" [ngTemplateOutletContext]="{file: media.mediaFile}"></ng-container>
    <ng-container *ngIf="media.relatedDocuments">
      <h3>Gerelateerde bestanden</h3>
      <ng-container [ngTemplateOutlet]="fileDownload" [ngTemplateOutletContext]="{file: relatedFile}" *ngFor="let relatedFile of media.relatedDocuments"></ng-container>
    </ng-container>
  </div>
</div>
<ng-template #fileDownload let-file="file">
  <a [href]="file.url" download=""
     class="border border-[#C9E9FB] inline-flex rounded-lg p-3 items-center inline-block mb-4" target="_blank">
    <div class="p-3 bg-primary rounded-md mr-4">
      <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-9 w-9">
        <path
          d="M18 24L10.5 16.5L12.6 14.325L16.5 18.225V6H19.5V18.225L23.4 14.325L25.5 16.5L18 24ZM9 30C8.175 30 7.46875 29.7063 6.88125 29.1188C6.29375 28.5312 6 27.825 6 27V22.5H9V27H27V22.5H30V27C30 27.825 29.7063 28.5312 29.1188 29.1188C28.5312 29.7063 27.825 30 27 30H9Z"
          fill="#F8D100"/>
      </svg>
    </div>
    <div class="flex flex-col">
      <span class="mb-1">Download document</span>
      <span class="text-[#6A458B]">{{ file.name }}</span>
      <span class="text-[#6A458B] text-right">{{ file.mime|mime: 'extension' }} | {{file.size|bytes}}</span>
    </div>
  </a>
</ng-template>
