<div [ngClass]="{'container mx-auto': withContainer, 'text-white': isWhiteText, 'list-purple': isPurpleList}" [ngStyle]="{'background-color': paragraph.color?.color}">
  <div class="flex flex-col md:flex-row  min-h-[420px]">
    <div class="leading-8 p-4 mt-10 flex items-center" [ngClass]="textContainerClasses">
      <div>
        <h2 *ngIf="paragraph.title" class="mb-2">{{paragraph.title}}</h2>
        <app-text-content [content]="paragraph.content.processed"></app-text-content>
      </div>
    </div>
    <div class="rounded-lg" [ngClass]="imageContainerClasses">
      <img [src]="paragraph.image.mediaImage?.url" [alt]="paragraph.image.alt" *ngIf="paragraph.image" class="txt-img h-full object-cover">
    </div>
  </div>
</div>
