import {Component, Input, OnInit} from '@angular/core';
import {TileParagraph} from "../../../models/node.model";
import {NgIf, NgTemplateOutlet} from "@angular/common";
import {RouterLink} from "@angular/router";
import {StripTagsPipe} from "../../../pipes/strip-tags.pipe";

@Component({
  selector: 'app-tile-paragraph',
  standalone: true,
  imports: [
    NgIf,
    RouterLink,
    NgTemplateOutlet,
    StripTagsPipe
  ],
  templateUrl: './tile-paragraph.component.html',
  styleUrl: './tile-paragraph.component.scss'
})
export class TileParagraphComponent implements OnInit   {
  @Input() paragraph!: TileParagraph;

  bgClass:string = '';

  ngOnInit() {
    this.bgClass = 'bg-[#ffffff]';
    if (this.paragraph.color?.color) {
      this.bgClass = `bg-[${this.paragraph.color.color}]`;
      if (this.paragraph.color.color === '#481249') {
        this.bgClass += ' text-white';
      }
    }
  }
  get visual(): boolean {
    return this.paragraph.visual ?? false;
  }

}
