import {Directive, ElementRef, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';

@Directive({standalone: true, selector: '[runScripts]'})
export class RunScriptsDirective implements OnInit {
  constructor(private elementRef: ElementRef, @Inject(PLATFORM_ID) private platformId: object) {
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => { // wait for DOM rendering
        this.reinsertScripts();
      });
    }
  }

  reinsertScripts(): void {
    const scripts = this.elementRef.nativeElement.getElementsByTagName('script') as HTMLScriptElement[];
    // console.log('scripts', scripts);
    const scriptsInitialLength = scripts.length;
    for (let i = 0; i < scriptsInitialLength; i++) {
      const script = scripts[i];
      if (!script.classList.contains('copied-script')) {
        const scriptCopy = document.createElement('script') as HTMLScriptElement;

        const attributeNames = scripts[i].getAttributeNames();

        // This ensures all existing attributes get re-added to the cloned script.
        attributeNames.forEach(attributeName => {
          const attribute = script.getAttribute(attributeName);
          if(attribute) {
            scriptCopy.setAttribute(attributeName, attribute);
          }
        });

        //console.log('runScriptsDirective script', scripts[i].getAttributeNames());
        if (script.innerHTML) {
          scriptCopy.innerHTML = script.innerHTML;
          scriptCopy.async = true;
        } else if (script.src) {
          scriptCopy.src = script.src;
          scriptCopy.async = false;
          scriptCopy.defer = true;
        }
        if(script.parentNode) {
          script.parentNode.replaceChild(scriptCopy, script);
        }
      }
    }
  }
}
