import {Component, Input, OnInit} from '@angular/core';
import {CallToActionParagraph} from "../../../models/node.model";
import {NgClass, NgIf} from "@angular/common";
import {RouterLink} from "@angular/router";

@Component({
  selector: 'app-call-to-action-paragraph',
  standalone: true,
  imports: [
    NgIf,
    RouterLink,
    NgClass
  ],
  templateUrl: './call-to-action-paragraph.component.html',
  styleUrl: './call-to-action-paragraph.component.scss'
})
export class CallToActionParagraphComponent implements OnInit{
  @Input() paragraph!: CallToActionParagraph;
  @Input() withContainer= true;
  bgClass = '';

  buttonBgClass = 'bg-secondary';
  borderClass = 'border-primary';

  ngOnInit() {
    if(this.paragraph.color?.color) {
      this.bgClass = 'bg-['+this.paragraph.color.color+']'
      if(this.paragraph.color.color == '#481249') {
        this.bgClass += ' text-white';
        this.borderClass = 'border-[var(--tertiary-default)]'; 
      }
      if(this.paragraph.color.color == '#F8D100') {
        this.buttonBgClass = 'bg-primary text-white';
      }
    }
  }

}
