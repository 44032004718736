import {Component, Input, OnInit} from '@angular/core';
import {DownloadsParagraph} from "../../../models/node.model";
import {JsonPipe, NgClass, NgForOf, NgIf, NgTemplateOutlet} from "@angular/common";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-download-paragraph',
  standalone: true,
  imports: [
    NgIf,
    NgForOf,
    NgTemplateOutlet,
    JsonPipe,
    NgClass
  ],
  templateUrl: './download-paragraph.component.html',
  styleUrl: './download-paragraph.component.scss'
})
export class DownloadParagraphComponent implements OnInit{
  @Input() paragraph!: DownloadsParagraph;
  @Input() withContainer = true;

  ngOnInit() {
    console.log('DownloadParagraph.paragraph', this.paragraph)
  }

  protected readonly environment = environment;
}
