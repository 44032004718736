import {Component, Input} from '@angular/core';
import {TabsItemParagraph} from "../../../models/node.model";

@Component({
  selector: 'app-tabs-item-paragraph',
  standalone: true,
  imports: [],
  templateUrl: './tabs-item-paragraph.component.html',
  styleUrl: './tabs-item-paragraph.component.scss'
})
export class TabsItemParagraphComponent {
  @Input() paragraph!:TabsItemParagraph;
}
