import {Component, Input} from '@angular/core';
import {BreadCrumb, DrupalNode} from "../../../models/node.model";
import {BreadcrumbsComponent} from "../../breadcrumbs/breadcrumbs.component";
import {DatePipe, NgClass, NgIf} from "@angular/common";
import {NewsletterBlockComponent} from "../../newsletter-block/newsletter-block.component";
import {ParagraphsComponent} from "../../paragraphs/paragraphs.component";
import {ShareLinksComponent} from "../../../share-links/share-links.component";
import {TableOfContentsComponent} from "../../table-of-contents/table-of-contents.component";
import {HeaderEntityComponent} from "../../header-entity/header-entity.component";

@Component({
  selector: 'app-page-node',
  standalone: true,
    imports: [
        BreadcrumbsComponent,
        DatePipe,
        NewsletterBlockComponent,
        NgIf,
        ParagraphsComponent,
        ShareLinksComponent,
        TableOfContentsComponent,
        NgClass,
        HeaderEntityComponent
    ],
  templateUrl: './page-node.component.html',
  styleUrl: './page-node.component.scss'
})
export class PageNodeComponent {
  @Input() node!: DrupalNode;
  @Input() breadcrumbs!: Array<BreadCrumb>;
}
