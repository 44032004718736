import {Component, Input, OnInit} from '@angular/core';
import {RouterLink} from "@angular/router";
import {CardData} from "../../../../models/node.model";
import {DatePipe, NgIf, NgTemplateOutlet} from "@angular/common";
import {EventDateComponent} from "../event-date/event-date.component";
import {StripTagsPipe} from "../../../../pipes/strip-tags.pipe";

@Component({
  selector: 'app-event-card',
  standalone: true,
  imports: [
    RouterLink,
    NgTemplateOutlet,
    NgIf,
    EventDateComponent,
    DatePipe,
    StripTagsPipe
  ],
  templateUrl: './event-card.component.html',
  styleUrl: './event-card.component.scss'
})
export class EventCardComponent implements OnInit{
  @Input() cardData!: CardData;
  imgUrl: string | undefined;
  ngOnInit() {
    // Workaround because if you do this in template TS complains that it is possibly undefined
    if(this.cardData.image?.mediaImage) {
      this.imgUrl = this.cardData.image?.mediaImage?.variations[0].url;
    }
  }

}
