<div id="paragraph-{{paragraph.id}}" class="paragraph--button-menu my-10">
  <h2 *ngIf="paragraph.title">{{ paragraph.title }}</h2>
  <ng-container *ngIf="paragraph.buttonMenuLinks">
    <ul class="inline-flex">
      <li *ngFor="let link of paragraph.buttonMenuLinks" class="font-bold text-primary">
        <a [routerLink]="link.internal ? link.url : null" class="inline-block py-4 px-8 rounded-md mx-2"
           [routerLinkActive]="['!bg-secondary']"
           #routeLink="routerLinkActive" [ngClass]="{'bg-[#F7F7F7]': !routeLink.isActive}"
           *ngIf="link.internal">{{ link.title }}</a>
        <a [href]="link.internal ? link.url : null" class="inline-block bg-[#F7F7F7] py-4 px-8 rounded-md"
           *ngIf="!link.internal">{{ link.title }}</a>
      </li>
    </ul>
  </ng-container>
</div>
