<div class="paragraph--tabs my-10">
  <h2 *ngIf="paragraph.title">{{ paragraph.title }}</h2>
<!--  <div class=" dark:border-gray-700" [ngClass]="{'container': withContainer}">
    <ul class="flex flex-wrap -mb-px text-sm font-medium text-center" id="default-tab-{{paragraph.id}}" [attr.data-tabs-toggle]="'#tabs-'+paragraph.id" data-tabs-active-classes="bg-[#F7F7F7] !border-[#F7F7F7]" role="tablist">
      <li class="me-2" role="presentation" *ngFor="let tabsItem of paragraph.tabsItems; let index=index">
        <button class="inline-block py-4 px-6 rounded-t-sm border !border-b-0  border-tertiary-default text-primary font-bold" id="tab-{{paragraph.id}}-{{index}}" [attr.data-tabs-target]="'#tab-item-'+paragraph.id+'-' + index" type="button" role="tab" [attr.aria-controls]="'tab-item-'+paragraph.id+'-'+index" [attr.aria-selected]="index === 0">
          {{tabsItem.title}}
        </button>
      </li>
    </ul>
  </div>
  <div id="tabs-{{paragraph.id}}">
    <div class="p-4 bg-[#F7F7F7]" [ngClass]="{'hidden': index !== 0}" id="tab-item-{{paragraph.id}}-{{index}}" role="tabpanel" [attr.aria-labelledby]="'tab-'+ paragraph.id +'-'+index" *ngFor="let tabsItem of paragraph.tabsItems; let index=index">
      <div class="text-sm text-gray-500 dark:text-gray-400" [innerHTML]="tabsItem.content.processed"></div>
    </div>
  </div>-->
  <p-tabView>
    <p-tabPanel [header]="tabsItem.title ? tabsItem.title : ''" *ngFor="let tabsItem of paragraph.tabsItems;"><div [innerHtml]="tabsItem.content.processed"></div></p-tabPanel>
  </p-tabView>
</div>
