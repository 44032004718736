import {ApplicationConfig, importProvidersFrom, LOCALE_ID} from '@angular/core';
import {provideRouter, withInMemoryScrolling} from '@angular/router';

import {routes} from './app.routes';
import {provideClientHydration, withHttpTransferCacheOptions} from '@angular/platform-browser';
import {Apollo, APOLLO_OPTIONS} from "apollo-angular";
import {ApolloClientOptions, ApolloLink, InMemoryCache} from '@apollo/client/core';
import {HttpLink} from "apollo-angular/http";
import {environment} from "../environments/environment";
import {provideHttpClient, withFetch} from "@angular/common/http";
import {registerLocaleData} from "@angular/common";
import localeNl from "@angular/common/locales/nl"
import {LoadingBarHttpClientModule} from "@ngx-loading-bar/http-client";

import {provideAnimations} from "@angular/platform-browser/animations";

registerLocaleData(localeNl);

declare global {
  interface Window {
    a2a?: any;
  }
}

export const appConfig: ApplicationConfig = {
  providers: [
    {provide: LOCALE_ID, useValue: 'nl'},
    provideRouter(routes, withInMemoryScrolling({
        scrollPositionRestoration: "top",
        anchorScrolling: 'enabled',
      }),
    ),
    provideHttpClient(withFetch()),
    provideAnimations(),
    importProvidersFrom(LoadingBarHttpClientModule),
    {
      provide: APOLLO_OPTIONS,
      useFactory: (
        httpLink: HttpLink,
      ): ApolloClientOptions<unknown> => ({
        link: ApolloLink.from([
          httpLink.create({uri: environment.drupalUrl + '/graphql'}),
        ]),
        cache: new InMemoryCache({
          possibleTypes: {
            ParagraphInterface: ["ParagraphVideo", "ParagraphAccordion", "ParagraphAccordionItem", "ParagraphButtonMenu", "ParagraphTabsItem", "ParagraphTab", "ParagraphMenu", "ParagraphMenuItem", "ParagraphOverview", "ParagraphCallToAction", "ParagraphEmbed", "ParagraphWebform", "ParagraphDownload", "ParagraphLink", "ParagraphFromLibrary", "ParagraphColumn", "ParagraphText", "ParagraphQuote", "ParagraphTextAndImage", "ParagraphImage", "ParagraphThreeColumns", "ParagraphTile", "ParagraphColumns"],
            NodeInterface: ["NodeEvent", "NodeOverviewPage", "NodePage", "NodeNews", "NodeInterview", "NodeThemePage"],
            DataInterface: ["DataHeader", "DataPerson"],
            MediaInterface: ["MediaDocument", "MediaImage"],
          }
        }),
        connectToDevTools: true
      }),
      deps: [HttpLink],
    },
    Apollo,
    provideClientHydration(withHttpTransferCacheOptions({includePostRequests: environment.ssrIncludePost}))
  ]
};
