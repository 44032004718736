import {Component, Input, OnInit} from '@angular/core';
import {ImageParagraph} from "../../../models/node.model";
import {NgClass, NgIf} from "@angular/common";

@Component({
  selector: 'app-image-paragraph',
  standalone: true,
  imports: [
    NgIf,
    NgClass
  ],
  templateUrl: './image-paragraph.component.html',
  styleUrl: './image-paragraph.component.scss'
})
export class ImageParagraphComponent implements OnInit{
  @Input() paragraph!: ImageParagraph;
  @Input() withContainer = true;

  ngOnInit() {
    //console.log('imageParagraph paragraph', this.paragraph)
  }
}
