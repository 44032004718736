import {Component, Input, OnInit} from '@angular/core';
import {OverviewParagraph} from "../../../models/node.model";
import {GraphQLService} from "../../../services/graph-ql.service";
import {NgClass, NgIf} from "@angular/common";
import {NewsOverviewComponent} from "../../drupal-entity/overview-node/news-overview/news-overview.component";

@Component({
  selector: 'app-overview-paragraph',
  standalone: true,
  imports: [
    NgIf,
    NewsOverviewComponent,
    NgClass
  ],
  templateUrl: './overview-paragraph.component.html',
  styleUrl: './overview-paragraph.component.scss'
})
export class OverviewParagraphComponent implements OnInit{
  @Input() paragraph!: OverviewParagraph;
  @Input() withContainer = true;


  ngOnInit() {
    if(this.paragraph.overviewType == 'news_inspiration') {

    }
  }

}
