<app-header-entity [header]="node.header" *ngIf="node.header"></app-header-entity>
<div class="container mx-auto" [ngClass]="{'no-aside': !node.sidebarParagraphs && !node.showToc, 'aside': node.sidebarParagraphs || node.showToc}">
  <div class="pt-9 pb-14">
    <app-breadcrumbs [breadcrumbs]="breadcrumbs" *ngIf="breadcrumbs"></app-breadcrumbs>
  </div>
  <div class="">
    <span class="pb-3">{{ interviewMetaString }}</span>
    <span class="pb-8 block">
            <h1 class="font-bold text-3xl">{{ node.title }}</h1>
            <p class="text-2xl tracking-tighter font-light" *ngIf="node.subtitle">{{ node.subtitle }}</p>
          </span>
    <img *ngIf="node.image?.mediaImage?.variations?.[0]?.url" src="{{node.image?.mediaImage?.variations?.[0]?.url}}"
         alt="{{node.image?.mediaImage?.alt}}" loading="lazy" class="rounded-lg mb-10">
    <div class="font-bold leading-normal intro" *ngIf="node.body" [innerHTML]="node.body.processed"></div>
  </div>
  <app-paragraphs [paragraphs]="node.paragraphs" *ngIf="node.paragraphs" [withContainer]="false"></app-paragraphs>
<app-share-links></app-share-links>
</div>
