<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/instantsearch.css@8.4.0/themes/reset-min.css"
      integrity="sha256-D+cGTF0LVHjuEf+CDRkHeNw/KTHPg47t1AA/qmzxgtA=" crossorigin="anonymous">

<div class="container mx-auto">
  <div class="grid grid-cols-1 lg:grid-cols-6 gap-10">
    <!-- Zijbalk (Facets), komt boven de zoekresultaten op mobiel -->
    <div class="col-span-1 lg:col-span-2 border border-tertiary-default rounded-xl p-10 facets order-1 lg:order-2">
      <h2 class="mb-10 leading-none font-bold text-3xl hidden md:block">Zoeken</h2>
      <div class="border-b border-primary pb-8">
        <label for="search-input" class="font-bold mt-0 lg:mt-10 mb-5 text-[20px] block">Zoekterm</label>
        <input id="search-input" type="search" [value]="query" (input)="handleInput($event)"/>
        <p class="text-[15.5px] text-[#746377] block ml-3 mt-1">Vul hier uw zoekterm in</p>
      </div>

      <div class="py-8 border-b border-primary">
        <h2 class="mb-8 leading-none text-3xl">Type</h2>
        <div id="type-list"></div>
      </div>

      <!--<div class="py-8">
        <div id="created-list"></div>
        <div class="py-8">
          <div id="clear-refinements"></div>
        </div>
      </div>-->

      <div class="py-8 border-b border-primary">
        <h2 class="mb-8 leading-none text-3xl">Onderwerpen</h2>
        <div id="subject_name-list"></div>
      </div>

      <div class="py-8">
        <h2 class="mb-8 leading-none text-3xl">Onderwijstype</h2>
        <div id="education_name-list"></div>
      </div>

      <div class="pt-4">
        <div id="clear-refinements"></div>
      </div>
    </div>

    <!-- Zoekresultaten sectie -->
    <div class="col-span-1 lg:col-span-4 search-results mt-2 order-2 lg:order-1">
      <p *ngIf="isNotFound" class="text-xl">Onze site is onlangs vernieuwd. De door u gevraagde pagina bestaat helaas niet (meer). Er is een zoekopdracht gedaan naar <strong>{{query}}</strong>.</p>
      <div id="stats"></div>
      <!--<p *ngIf="totalResults === 0">Er zijn geen resultaten gevonden.</p>
      --><p class="my-4" *ngIf="query && !isNotFound">Je hebt gezocht op de volgende zoekterm(en): <span class="font-bold">{{ query }}</span></p>
      <div id="hits"></div>
      <ul>
        <li *ngFor="let hit of hits; ">
          <div>
            <a [routerLink]="hit['url']" class="border-b block border-tertiary-default py-7">
              <ng-container *ngIf="hit['entity_type'] == 'node'">
                <h2 class="mb-4 text-3xl">{{ hit['title'] }}</h2>
                <span class="text-tertiary-dark font-[600] mb-4 block">https://gezondeschool.nl{{ hit['url'] }}</span>
                <span *ngIf="hit['_snippetResult']">{{ hit['_snippetResult']['value'] }}</span>
                <div [innerHTML]="hit['body'] | stripTags" *ngIf="hit['_snippetResult'] && $any(hit['_snippetResult']['rendered_item']).matchLevel == 'none'"></div>
                <div *ngIf="hit['_snippetResult'] && $any(hit['_snippetResult']['rendered_item']).matchLevel !== 'none'" [innerHtml]="$any(hit['_snippetResult']['rendered_item']).value"></div>
                <span class="block text-[15.5px] text-[#746377] mt-4">Publicatiedatum: {{ hit['created'] * 1000 | date: 'd MMMM YYYY' }}</span>
              </ng-container>
              <ng-container *ngIf="hit['entity_type'] == 'media'">
                <h2 class="mb-4 text-3xl">{{ hit['name'] }}</h2>
                <span class="text-tertiary-dark font-[600] mb-4 block">https://gezondeschool.nl{{ hit['url'] }}</span>
                <span *ngIf="hit['_snippetResult']">{{ hit['_snippetResult']['value'] }}</span>
                <div [innerHTML]="hit['field_description'] | stripTags" *ngIf="hit['_snippetResult'] && $any(hit['_snippetResult']['rendered_item']).matchLevel == 'none'"></div>
                <div *ngIf="hit['_snippetResult'] && $any(hit['_snippetResult']['rendered_item']).matchLevel !== 'none'" [innerHtml]="$any(hit['_snippetResult']['rendered_item']).value"></div>
              </ng-container>
            </a>
          </div>
        </li>
      </ul>
      <div id="pager"></div>
    </div>

  </div>
</div>
