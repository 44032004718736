import {Component, forwardRef, Input} from '@angular/core';
import {LibraryParagraph} from "../../../models/node.model";
import {ParagraphsComponent} from "../paragraphs.component";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-library-paragraph',
  standalone: true,
  imports: [
    forwardRef(() => ParagraphsComponent),
    NgIf
  ],
  templateUrl: './library-paragraph.component.html',
  styleUrl: './library-paragraph.component.scss'
})
export class LibraryParagraphComponent {
  @Input() paragraph!: LibraryParagraph;

}
