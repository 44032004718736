<div class="border border-tertiary-default rounded-2xl py-2 px-3 lg:py-10 lg:px-12">
  <h2 *ngIf="paragraph.title" class="text-lg">{{ paragraph.title }}</h2>
  <ul>
    <li *ngFor="let menuItem of paragraph.menuItems" class="border border-tertiary-default text-primary mb-1">
      <ng-container [ngTemplateOutlet]="menuItemTpl"
                    [ngTemplateOutletContext]="{link: menuItem, classes: 'px-6 py-2 font-bold'}"></ng-container>
      <ul *ngIf="menuItem.submenuItems" class="mx-3">
        <li *ngFor="let menuItem of menuItem.submenuItems" class="text-tertiary-dark">
          <ng-container [ngTemplateOutlet]="menuItemTpl"
                        [ngTemplateOutletContext]="{link: menuItem, classes: 'px-6 py-2 border-b border-gray-200'}"></ng-container>
        </li>
      </ul>
    </li>
  </ul>
</div>
<ng-template #menuItemTpl let-link="link" let-classes="classes">
  <a [routerLink]="link.link.url" *ngIf="link.link.internal"
     class="block hover:underline focus:outline {{classes}}">{{ link.link.title }}</a>
  <a [href]="link.link.url" *ngIf="!link.link.internal"
     class="block hover:underline focus:outline {{classes}}">{{ link.link.title }}</a>
</ng-template>
