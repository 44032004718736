import { Injectable } from '@angular/core';
import {Apollo, gql} from "apollo-angular";
import {Observable} from "rxjs";
import {ApolloQueryResult} from "@apollo/client/core";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class GraphQLService {

  constructor(private apollo: Apollo) { }


  getNode(path: string): Observable<ApolloQueryResult<any>> {
    console.log('path', path)
    if (path == '/') {
      path = `node/${environment.frontPageNid}`;
    }
    return this.apollo.query({
      query: gql`query nodeByPath {
        route(path: "${path}") {
          ... on RouteRedirect {
            __typename
            status
            url
            internal
          }
          ... on RouteInternal {
            __typename
            breadcrumbs {
              internal
              ... on Link {
                url
                title
              }
            }
            entity {
              ... on MediaInterface {
                ... on MediaDocument {
                  __typename
                  id
                  publishedDate {
                    timestamp
                  }
                  name
                  mediaFile {
                    size
                    mime
                    url
                    name
                    description
                  }
                  description {
                    processed
                  }
                  relatedDocuments {
                    url
                    name
                    size
                    mime
                  }
                }
              }
              ... on NodeInterface {
                __typename
                metatag {
                  ... on MetaTagValue {
                    tag
                    attributes {
                      name
                      content
                    }
                  }
                }
                title
                id
                created {
                  time
                }
                ... on NodeEvent {
                  header {
                    ...dataHeaderFragment
                  }
                  body {
                    processed
                  }
                  location
                  eventDate {
                    timestamp
                  }
                  eventDateRange {
                    start {
                      timestamp
                    }
                    end {
                      timestamp
                    }
                  }
                  paragraphs {
                    __typename
                    ...paragraphVideoFragment
                    ...paragraphTabsFragment
                    ...paragraphButtonMenuFragment
                    ...paragraphLinksFragment
                    ...paragraphWebformFragment
                    ...paragraphEmbedFragment
                    ...paragraphFromLibraryFragment
                    ...paragraphTextAndImageFragment
                    ...paragraphTileFragment
                    ...paragraphTextFragment
                    ...paragraphQuoteFragment
                    ...paragraphColumnFragment
                    ...paragraphCallToActionFragment
                    ...paragraphOverviewFragment
                    ...paragraphAccordionFragment
                    ...paragraphImageFragment
                  }
                }
                ... on NodeThemePage {
                  header {
                    ...dataHeaderFragment
                  }
                  image {
                    ... on MediaImage {
                      mediaImage {
                        alt
                        url
                      }
                    }
                  }
                  showToc
                  body {
                    processed
                  }
                  paragraphs {
                    __typename
                    ...paragraphVideoFragment
                    ...paragraphTabsFragment
                    ...paragraphButtonMenuFragment
                    ...paragraphLinksFragment
                    ...paragraphWebformFragment
                    ...paragraphEmbedFragment
                    ...paragraphFromLibraryFragment
                    ...paragraphTextAndImageFragment
                    ...paragraphTileFragment
                    ...paragraphTextFragment
                    ...paragraphQuoteFragment
                    ...paragraphColumnFragment
                    ...paragraphCallToActionFragment
                    ...paragraphOverviewFragment
                    ...paragraphAccordionFragment
                    ...paragraphImageFragment
                  }
                  sidebarParagraphs {
                    __typename
                    ...paragraphFromLibraryFragment
                    ...paragraphMenuFragment
                  }
                }
                ... on NodeOverviewPage {
                  overviewType
                  header {
                    ...dataHeaderFragment
                  }
                  body {
                    processed
                  }
                  paragraphs {
                    __typename
                    ...paragraphVideoFragment
                    ...paragraphTabsFragment
                    ...paragraphButtonMenuFragment
                    ...paragraphLinksFragment
                    ...paragraphWebformFragment
                    ...paragraphEmbedFragment
                    ...paragraphFromLibraryFragment
                    ...paragraphTextAndImageFragment
                    ...paragraphTileFragment
                    ...paragraphTextFragment
                    ...paragraphQuoteFragment
                    ...paragraphColumnFragment
                    ...paragraphCallToActionFragment
                    ...paragraphOverviewFragment
                    ...paragraphAccordionFragment
                    ...paragraphImageFragment
                  }
                }
                ... on NodePage {
                  header {
                    ...dataHeaderFragment
                  }
                  image {
                    ... on MediaImage {
                      mediaImage {
                        alt
                        url
                      }
                    }
                  }
                  showToc
                  id
                  body {
                    processed
                  }
                  readTime {
                    minutes
                    seconds
                  }
                  title
                  paragraphs {
                    __typename
                    ...paragraphVideoFragment
                    ...paragraphTabsFragment
                    ...paragraphButtonMenuFragment
                    ...paragraphDownloadFragment
                    ...paragraphLinksFragment
                    ...paragraphWebformFragment
                    ...paragraphEmbedFragment
                    ...paragraphTextFragment
                    ...paragraphQuoteFragment
                    ...paragraphTextAndImageFragment
                    ...paragraphFromLibraryFragment
                    ...paragraphColumnFragment
                    ...paragraphCallToActionFragment
                    ...paragraphOverviewFragment
                    ...paragraphAccordionFragment
                    ...paragraphImageFragment
                    ...paragraphTileFragment
                  }
                  sidebarParagraphs {
                    __typename
                    ...paragraphFromLibraryFragment
                    ...paragraphMenuFragment
                  }
                }
                ... on NodeInterview {
                  header {
                    ...dataHeaderFragment
                  }
                  interviewLocation
                  interviewName
                  interviewSchool
                  image {
                    ... on MediaImage {
                      mediaImage {
                        alt
                        url
                      }
                    }
                  }
                  id
                  title
                  readTime {
                    minutes
                    seconds
                  }
                  body {
                    processed
                  }
                  paragraphs {
                    __typename
                    ...paragraphTabsFragment
                    ...paragraphButtonMenuFragment
                    ...paragraphCallToActionFragment
                    ...paragraphLinksFragment
                    ...paragraphDownloadFragment
                    ...paragraphWebformFragment
                    ...paragraphEmbedFragment
                    ...paragraphTextFragment
                    ...paragraphQuoteFragment
                    ...paragraphTextAndImageFragment
                    ...paragraphColumnFragment
                    ...paragraphOverviewFragment
                    ...paragraphAccordionFragment
                    ...paragraphImageFragment
                    ...paragraphVideoFragment
                  }
                }
                ... on NodeNews {
                  header {
                    ...dataHeaderFragment
                  }
                  image {
                    ... on MediaImage {
                      mediaImage {
                        alt
                        url
                      }
                    }
                  }
                  id
                  readTime {
                    minutes
                    seconds
                  }
                  title
                  body {
                    processed
                  }
                  paragraphs {
                    __typename
                    ...paragraphTabsFragment
                    ...paragraphButtonMenuFragment
                    ...paragraphCallToActionFragment
                    ...paragraphLinksFragment
                    ...paragraphDownloadFragment
                    ...paragraphWebformFragment
                    ...paragraphEmbedFragment
                    ...paragraphTextFragment
                    ...paragraphQuoteFragment
                    ...paragraphTextAndImageFragment
                    ...paragraphColumnFragment
                    ...paragraphOverviewFragment
                    ...paragraphAccordionFragment
                    ...paragraphImageFragment
                    ...paragraphVideoFragment
                  }
                }
              }
            }
          }
        }
      }

      fragment dataHeaderFragment on DataInterface {
        id
        ... on DataHeader {
          title
          highlightedNode {
            ... on NodeNews {
              subject {
                ... on TermSubject {
                  name
                }
              }
              education {
                ... on TermEducationType {
                  name
                }
              }
              path
              title
              readTime {
                minutes
                seconds
              }
              body {
                processed
              }
              created {
                time
              }
              __typename
              image {
                ... on MediaImage {
                  name
                  mediaImage {
              variations(styles: [HEADER, MASKED_IMAGE_WITH_OVERLAY]) {
                url
                name
              }
            }
          }
        }
      }
    }
    image {
      ... on MediaImage {
        id
        mediaImage {
          url
          variations(styles: [HEADER, MASKED_IMAGE_WITH_OVERLAY]) {
            url
            name
          }
        }
      }
    }
    content {
            processed
          }
          intro {
            processed
          }
        }
      }

      fragment paragraphImageFragment on ParagraphInterface {
        id
        ... on ParagraphImage {
          caption {
            processed
          }
          title
          imageParagraphImage {
            ... on MediaImage {
              id
              mediaImage {
                url
              }
            }
          }
        }
      }

      fragment paragraphMenuFragment on ParagraphInterface {
        id
        __typename
        ... on ParagraphMenu {
          title
          menuItems {
            __typename
            ... on ParagraphMenuLink {
              link {
                internal
                url
                title
              }
              submenuItems {
                __typename
                ... on ParagraphMenuLink {
                  link {
                    internal
                    url
                    title
                  }
                }
              }
            }
          }
        }
      }

      fragment paragraphAccordionFragment on ParagraphInterface {
        ... on ParagraphAccordion {
          id
          title
          accordionItems {
            ...paragraphAccordionItemFragment
          }
        }
      }

      fragment paragraphAccordionItemFragment on ParagraphInterface {
        ... on ParagraphAccordionItem {
          id
          title
          collapsible
          content {
            processed
          }
        }
      }

      fragment paragraphButtonMenuFragment on ParagraphInterface {
        ... on ParagraphButtonMenu {
          __typename
          title
          buttonMenuLinks {
            internal
            url
            title
          }
        }
      }

      fragment paragraphTabsItemFragment on ParagraphInterface  {
        ... on ParagraphTabsItem {
          id
          __typename
          title
          content {
            processed
          }
        }
      }

      fragment paragraphTabsFragment on ParagraphInterface {
        ... on ParagraphTab {
          id
          __typename
          title
          tabsItems {
            ...paragraphTabsItemFragment
          }
        }
      }

      fragment paragraphOverviewFragment on ParagraphInterface {
        ... on ParagraphOverview {
          title
          overviewType
        }
      }

      fragment paragraphCallToActionFragment on ParagraphInterface {
        ... on ParagraphCallToAction {
          title
          imagePosition
          ctaContent {
            processed
          }
          link {
            url
            title
          }
          color {
            color
          }
          ctaImage {
            ... on MediaImage {
              id
              mediaImage {
                url
              }
            }
          }
        }
      }

      fragment paragraphDownloadFragment on ParagraphInterface {
        ... on ParagraphDownload {
          title
          files {
            ... on MediaInterface {
              name
              __typename
            }
            ... on MediaDocument {
              id
              mediaFile {
                url
              }
            }
            ... on MediaImage {
              id
              mediaImage {
                url
              }
            }
          }
        }
      }

      fragment paragraphLinksFragment on ParagraphInterface {
        ... on ParagraphLink {
          title
          links {
            url
            title
          }
        }
      }

      fragment paragraphEmbedFragment on ParagraphInterface {
        ... on ParagraphEmbed {
          title
          embed
        }
      }

      fragment paragraphWebformFragment on ParagraphInterface {
        ... on ParagraphWebform {
          title
          embed
        }
      }

      fragment paragraphVideoFragment on ParagraphInterface {
        ... on ParagraphVideo {
          title
          caption {
            processed
          }
          video {
            ... on MediaRemoteVideo {
              mediaRemoteVideo
            }
            ... on MediaVideo {
              id
              mediaOembedVideo
              name
            }
          }
        }
      }

      fragment paragraphQuoteFragment on ParagraphInterface {
        ... on ParagraphQuote {
          content {
            processed
          }
          title
          style
          hideIcon
          author {
            ... on DataInterface {
              title
              ... on DataPerson {
                location
                image {
                  ... on MediaImage {
                    mediaImage {
                      variations(styles:THUMBNAIL) {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      fragment paragraphFromLibraryFragment on ParagraphInterface {
        ... on ParagraphFromLibrary {
          id
          libraryParagraph {
            ... on ParagraphsLibraryItem {
              paragraphs {
                ...paragraphAccordionFragment
                ...paragraphColumnFragment
                ...paragraphMenuFragment
                ...paragraphTabsFragment
                ...paragraphButtonMenuFragment
                ...paragraphTileFragment
                ...paragraphTextFragment
                ...paragraphTextAndImageFragment
                ...paragraphCallToActionFragment
                ...paragraphVideoFragment
                ...paragraphImageFragment
                ...paragraphWebformFragment
                ...paragraphQuoteFragment
                ...paragraphEmbedFragment
                ...paragraphLinksFragment
                ...paragraphDownloadFragment
              }
            }
          }
        }
      }

      fragment paragraphTileFragment on ParagraphInterface {
        ... on ParagraphTile {
          tileLink {
            internal
            url
            title
          }
          title
          color {
            color
          }
          tileContent {
            processed
          }
          id
          tileImage {
            ... on MediaImage {
              mediaImage {
                alt
                url
                variations(styles: [TILE]) {
                  url
                }
              }
            }
          }
          visual
        }
      }

      fragment paragraphColumnFragment on ParagraphInterface {
        ... on ParagraphColumn {
          title
          columns
          color {
            color
          }
          id
          paragraphs {
            ...paragraphTextFragment
            ...paragraphTileFragment
          }
        }
      }

      fragment paragraphTextAndImageFragment on ParagraphInterface {
        ... on ParagraphTextAndImage {
          content {
            processed
          }
          imagePosition
          imageSize
          color {
            color
          }
          title
          image {
            ... on MediaImage {
              mediaImage {
                alt
                url
              }
            }
          }
        }
      }

      fragment paragraphTextFragment on ParagraphInterface {
        ... on ParagraphText {
          title
          id
          image {
            ... on MediaImage {
              mediaImage {
                alt
                url
              }
            }
          }
          color {
            color
          }
          collapsible
          content {
            processed
          }
        }
      }`
    })
  }

  /*getNodes(type: "news", offset: ${offset}, limit: ${limit}, educationIds: [${educationIds}], subjectIds: [${subjectIds}]) {
    total
    pageInfo {
      hasPreviousPage
      hasNextPage
      totalPages
    }
    items {
      url
      id
      title
      created
    ... on News {
        image {
          url
        }
        body
        readTime {
          minutes
          seconds
        }
      }
    }
  }*/

  toGraphFilterString(array: any) {

    let subjectString:string|null = null;

    console.log('toGraphFilterString array', array);

    if(array) {
      subjectString = "[";
      array.forEach(function(elem:string, idx:any, array:any) {
        if (idx === array.length-1) {
          subjectString += '"' + elem + '"]';
        } else {
          subjectString += '"' + elem + '",';
        }
      })
    }
    return subjectString;
  }

  getNewsOverviewNodes(offset: number = 0, limit: number = 20, educationIds: any = null, subjectIds: any = null): Observable<ApolloQueryResult<any>> {

    let educationIdsFilter = null;

    return this.apollo.query({
      query: gql`query newsOverview {
        genericNodesListGraph(filter: {type: ["news", "interview"], subject_id: ${this.toGraphFilterString(subjectIds)}, education_id: ${this.toGraphFilterString(educationIds)}}, pageSize: ${limit}, offset: ${offset}) {
          pageInfo {
            page
            total
            pageSize
            offset
          }
          results {
            ... on NodeInterview {
              path
              __typename
              readTime {
                minutes
                seconds
              }
              created {
                time
              }
              title
              body {
                processed
              }
              image {
                ... on MediaImage {
                  name
                  mediaImage {
                    variations(styles: [LARGE]) {
                      url
                    }
                  }
                }
              }
            }
            ... on NodeNews {
              subject {
                ... on TermSubject {
                  name
                }
              }
              education {
                ... on TermEducationType {
                  name
                }
              }
              path
              title
              readTime {
                minutes
                seconds
              }
              body {
                processed
              }
              created {
                time
              }
              __typename
              image {
                ... on MediaImage {
                  name
                  mediaImage {
                    variations(styles: [LARGE]) {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }`
    })
  }


  getEventOverviewNodes(offset: number = 0, limit: number = 20, educationIds: any = null, subjectIds: any = null): Observable<ApolloQueryResult<any>> {

    let educationIdsFilter = null;

    return this.apollo.query({
      query: gql`query newsOverview {
        genericNodesListGraph(filter: {type: ["event"], subject_id: ${this.toGraphFilterString(subjectIds)}, education_id: ${this.toGraphFilterString(educationIds)}}, pageSize: ${limit}, offset: ${offset}) {
          pageInfo {
            page
            total
            pageSize
            offset
          }
          results {
            ... on NodeEvent {
              eventDate {
                timestamp
              }
              eventDateRange {
                start {
                  timestamp
                }
                end {
                  timestamp
                }
              }
              path
              __typename
              readTime {
                minutes
                seconds
              }
              location
              onlineEvent
              created {
                time
              }
              title
              body {
                processed
              }
              subject {
                ... on TermSubject {
                  name
                }
              }
              education {
                ... on TermEducationType {
                  name
                }
              }
              image {
                ... on MediaImage {
                  name
                  mediaImage {
                    variations(styles: [LARGE]) {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }`
    })
  }

  getMenu(name: string): Observable<ApolloQueryResult<any>> {
    if (!name) {
      name = "MAIN";
    }
    return this.apollo.query({
      query: gql`query MenuByName {
        menu(name: ${name}) {
        name
        items {
          id
          title
          url
          internal
          children {
            id
            title
            url
            internal
            children {
              id
              url
              title
            }
          }
        }
      }}`
    });
  }


  }
