<div id="paragraph-{{paragraph.id}}" class="paragraph--text my-2 lg:my-10 container mx-auto gap-4 {{bgClass}}" 
     [ngClass]="{'container mx-auto gap-4': withContainer, 'narrow': !paragraph.collapsible, 'has-background': imgUrl}"
     [ngStyle]="imgUrl ? {'--background-image': 'url(' + imgUrl + ')'} : {}">
  <div class="flex items-center mb-16" *ngIf="paragraph.collapsible && paragraph.title">
    <svg class="mr-7" width="53" height="52" viewBox="0 0 53 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M52.7622 24.896L44.0213 2.33909C44.0213 2.33909 43.4062 0 41.7151 0L30.5565 0.00273273L2.06026 0.00959245C0.926366 0.00959245 0.0074013 1.08954 0.0074013 2.42214L0.00555214 14.8808L0.00184917 37.1306L0 49.5816C0 50.9141 0.919154 51.9941 2.05291 51.9941L30.5552 51.9982L41.7151 52C43.3548 52 44.0993 49.6577 44.0993 49.6577L52.7522 27.3213C52.7522 27.3213 53 26.6989 53 26.0946C53 25.4792 52.7622 24.896 52.7622 24.896Z" fill="#F8D100"/>
    </svg>
    <h2 *ngIf="paragraph.collapsible" class="text-6xl">{{paragraph.title}}</h2>
  </div>
  <h2 *ngIf="!paragraph.collapsible && paragraph.title">{{paragraph.title}}</h2>
  <div class="leading-8">
  <app-text-content [content]="paragraph.content.processed" *ngIf="!paragraph.collapsible"></app-text-content>
    <ng-container *ngIf="paragraph.collapsible">
      <div class="bg-[#FAFDFF] rounded-2xl border border-tertiary-default p-16" data-accordion="collapse">
        <div id="paragraph-{{paragraph.id}}" class="accordion-content block overflow-hidden min-h-16" 
        [ngClass]="{'hidden': !isExpanded, 'block': isExpanded}">
          <app-text-content [content]="paragraph.content.processed"></app-text-content>
        </div>
        <div class="text-center">
        <button class="inline-flex mx-auto items-center font-bold focus:outline outline-primary toggler group px-4 mt-10" [attr.data-accordion-target]="'#paragraph-'+paragraph.id" [attr.aria-expanded]="isExpanded"
        aria-controls="accordion-collapse-body-1"
        (click)="toggleExpand()" aria-controls="accordion-collapse-body-1">
          <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M32 24L29.2 21.2L26 24.4L26 16L22 16L22 24.4L18.8 21.2L16 24L24 32L32 24ZM44 24C44 26.7667 43.475 29.3667 42.425 31.8C41.375 34.2333 39.95 36.35 38.15 38.15C36.35 39.95 34.2333 41.375 31.8 42.425C29.3667 43.475 26.7667 44 24 44C21.2333 44 18.6333 43.475 16.2 42.425C13.7667 41.375 11.65 39.95 9.85 38.15C8.05 36.35 6.625 34.2333 5.575 31.8C4.525 29.3667 4 26.7667 4 24C4 21.2333 4.525 18.6333 5.575 16.2C6.625 13.7667 8.05 11.65 9.85 9.85C11.65 8.05 13.7667 6.625 16.2 5.575C18.6333 4.525 21.2333 4 24 4C26.7667 4 29.3667 4.525 31.8 5.575C34.2333 6.625 36.35 8.05 38.15 9.85C39.95 11.65 41.375 13.7667 42.425 16.2C43.475 18.6333 44 21.2333 44 24ZM40 24C40 19.5333 38.45 15.75 35.35 12.65C32.25 9.55 28.4667 8 24 8C19.5333 8 15.75 9.55 12.65 12.65C9.55 15.75 8 19.5333 8 24C8 28.4667 9.55 32.25 12.65 35.35C15.75 38.45 19.5333 40 24 40C28.4667 40 32.25 38.45 35.35 35.35C38.45 32.25 40 28.4667 40 24Z" fill="#481249"/>
          </svg>
          <span class="text group-hover:underline pl-4"></span>
        </button>
        </div>
      </div>
    </ng-container>
  </div>
</div>