<div [ngClass]="{'container mx-auto gap-4': withContainer}">
  <div class="paragraph--quote my-5 lg:my-10">
    <h2 *ngIf="paragraph.title" class="mb-1 text-lg lg:text-xl">{{ paragraph.title }}</h2>

    <!-- Quote container -->
    <div class="rounded-2xl relative flex flex-col sm:flex-row items-start sm:items-center pl-4 pr-4 py-4 sm:pl-5 sm:pr-5 sm:py-5 lg:pl-11 lg:pr-5 lg:py-10"
         [ngClass]="{
           'bg-secondary': paragraph.style == 'secondary',
           'bg-tertiary': paragraph.style == 'tertiary',
           'border-2 border-tertiary-default': paragraph.style == 'border'
         }">

      <!-- Icon container: positioned absolutely only on larger screens -->
      <div class="quote-icon lg:absolute lg:top-6 lg:left-6 mr-4 lg:mr-0 mb-4 sm:mb-0" *ngIf="!paragraphCopy.hideIcon">
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.028 6C6.684 11.184 1.5 19.68 1.5 29.04C1.5 36.672 6.108 41.136 11.436 41.136C16.476 41.136 20.22 37.104 20.22 32.352C20.22 27.6 16.908 24.144 12.588 24.144C11.724 24.144 10.572 24.288 10.284 24.432C11.004 19.536 15.612 13.776 20.22 10.896L14.028 6ZM38.796 6C31.596 11.184 26.412 19.68 26.412 29.04C26.412 36.672 31.02 41.136 36.348 41.136C41.244 41.136 45.132 37.104 45.132 32.352C45.132 27.6 41.676 24.144 37.356 24.144C36.492 24.144 35.484 24.288 35.196 24.432C35.916 19.536 40.38 13.776 44.988 10.896L38.796 6Z" fill="#481249"/>
        </svg>
      </div>

      <!-- Text content -->
      <div class="lg:ml-16"> <!-- Extra marge alleen op grotere schermen -->
        <div class="text-xl lg:text-2xl text-primary leading-normal" [innerHTML]="paragraph.content.processed"></div>

        <!-- Author container -->
        <div class="inline-flex items-center mt-4 lg:mt-8" *ngIf="paragraph.author">

          <!-- Author image -->
          <img *ngIf="paragraph.author.image?.mediaImage?.variations?.[0]?.url"
               [src]="paragraph.author.image.mediaImage?.variations?.[0]?.url"
               [alt]="paragraph.author.title || 'Author Image'"
               class="w-10 h-10 rounded-full object-cover mr-4">

          <!-- Author details -->
          <span class="flex items-center">
            <span class="font-bold text-base">{{ paragraph.author.title }}</span>

            <!-- Location with separator -->
            <span *ngIf="paragraph.author.location" class="flex items-center">
              <svg width="8" height="20" viewBox="0 0 8 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-10 mx-4">
                <path d="M5 0H8L3 20H0L5 0Z" fill="#481249"/>
              </svg>
              <span class="text-base">{{ paragraph.author.location }}</span>
            </span>
          </span>

        </div>
      </div>

    </div>
  </div>
</div>
