import { Component } from '@angular/core';

@Component({
  selector: 'app-newsletter-block',
  standalone: true,
  imports: [],
  templateUrl: './newsletter-block.component.html',
  styleUrl: './newsletter-block.component.scss'
})
export class NewsletterBlockComponent {

}
