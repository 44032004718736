import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input, OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {Paragraph} from "../../models/node.model";
import {NgForOf, NgIf, NgStyle} from "@angular/common";



@Component({
  selector: 'app-table-of-contents',
  templateUrl: './table-of-contents.component.html',
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    NgStyle
  ],
  styleUrls: ['./table-of-contents.component.scss']
})
export class TableOfContentsComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() sticky = false;
  @Input() content!: [Paragraph];

  show = false;

  @ViewChild('tableOfContentsWrapper') tableOfContentsElement!: ElementRef;

  headings:any;
  fixedMobile = false;
  fillSpaceStyle = {};

  constructor( private renderer: Renderer2) {
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes['content']) {
      this.mapHeadings();
    }
  }

  ngOnInit(): void {
    if (this.content) {
      this.mapHeadings();
    }
  }

  ngAfterViewInit(): void {
    //this.setHeightOfFillSpace();
    //this.toggleMobileView();
  }

/*  @HostListener('window:scroll', [])
  onWindowScroll() {
    //this.toggleMobileView();
  }*/


  scrollTo(id: string, collapseMenu = false): void {
    const element = document.querySelector(`#${id}`);
    if (element !== null) {
      const yOffset = (this.fixedMobile || window.innerWidth < 1200) ? -80 : -16;
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({top: y, behavior: 'smooth'});
    }
    if (collapseMenu) {
      // @ts-ignore
      document.getElementById('collapse-long-content-nav')?.classList.toggle('show');
      document.querySelector('#header-long-content-nav .accordion-toggle')?.classList.toggle('collapsed');
    }
  }

  private mapHeadings() {
    this.headings = this.content.map(
      paragraph => {
        if(paragraph.__typename == 'ParagraphFromLibrary') {
          //return null
          return {
            title: paragraph.libraryParagraph.paragraphs.title,
            target: 'paragraph-' + paragraph.libraryParagraph.paragraphs.id,
            children: []
          }
        } else {
          return {
            title: paragraph.title,
            target: 'paragraph-' + paragraph.id,
            children: []
          }
        }
      }
    )
    /*Object.values(this.content).forEach(value => {
      if (value.type === 'paragraph') {
        this.headings = value
          .filter(paragraph => paragraph.type[0].target_id === 'group')
          .filter(paragraph => paragraph.field_body_header[0])
          .map(paragraph => {
              const paragraphId = paragraph.id[0].value;
              let obj = {
                name: 'paragraph-' + paragraph.id[0].value
              };

              const childTitles = [];
              // If there are child paragraphs, loop them.
              if (value.additional_data[paragraphId] && value.additional_data[paragraphId].child_paragraphs) {
                const childParagraphs = value.additional_data[paragraphId].child_paragraphs;
                // Loop through all child paragraphs
                Object.keys(childParagraphs).forEach(key => {
                  if (isNumeric(key)) {
                    const child = childParagraphs[key];
                    // If child paragraph is of type content, and the header is set, add it to childTitles object.
                    if (child.type[0].target_id == 'content') {
                      if (child.field_body_header[0] && child.field_body_header[0].value) {
                        childTitles.push({
                          target: 'paragraph-' + child.id[0].value,
                          title: child.field_body_header[0].value
                        });
                      }
                    }

                  }
                });
              }
              return {
                title: paragraph.field_body_header[0].value,
                target: 'paragraph-' + paragraph.id[0].value,
                children: childTitles
              };
            }
          );
      }
    });*/
  }

  private toggleMobileView() {
    const element = this.tableOfContentsElement.nativeElement;
    const viewportOffset = element.getBoundingClientRect();
    const bottom = viewportOffset.bottom;
    const offset = 16;
    if (bottom + offset <= 0) {
      this.fixedMobile = true;
    } else {
      this.fixedMobile = false;
    }
  }

  private setHeightOfFillSpace() {
    const height = this.tableOfContentsElement.nativeElement.offsetHeight;
    this.fillSpaceStyle = {'height.px': height};
  }
}
