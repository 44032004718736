import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BypassSecurity } from "../../pipes/bypass-security.pipe";

@Component({
  selector: 'app-text-content',
  standalone: true,
  imports: [
    BypassSecurity
  ],
  templateUrl: './text-content.component.html',
  styleUrls: ['./text-content.component.scss']
})
export class TextContentComponent implements OnInit, OnChanges {
  @Input() withContainer = true;
  @Input() content: any;
  cleanedContent!: string;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnChanges(changes:SimpleChanges) {
    if(changes['content']) {
      this.cleanedContent = this.cleanContent(this.content);
    }
  }

  ngOnInit() {
    this.cleanedContent = this.cleanContent(this.content);
  }

  cleanContent(content: string): string {
    return content
      .replace(/style="[^"]*"/g, '')
      .replace(/_ngcontent-[a-z0-9-]+/g, '')
      .replace(/_nghost-[a-z0-9-]+/g, '');
  }
}
