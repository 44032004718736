import {Component, Input, OnInit} from '@angular/core';
import {TextContentComponent} from "../../text-content/text-content.component";
import {NgClass, NgIf, NgStyle} from "@angular/common";
import {TextParagraph} from "../../../models/node.model";

@Component({
  selector: 'app-text-paragraph',
  standalone: true,
  imports: [
    TextContentComponent,
    NgIf,
    NgClass,
    NgStyle
  ],
  templateUrl: './text-paragraph.component.html',
  styleUrl: './text-paragraph.component.scss'
})
export class TextParagraphComponent implements OnInit {
  @Input() withContainer = true;
  @Input() paragraph!: TextParagraph;

  isExpanded = false;
  bgClass = '';
  imgUrl: string | undefined;

  ngOnInit() {
    this.imgUrl = this.paragraph.image?.mediaImage?.url;
    if(this.paragraph.color) {
      this.bgClass = 'has-bg bg-['+this.paragraph.color.color+'] rounded-2xl p-10'
      if(this.paragraph.color.color == '#481249') {
        this.bgClass += ' !text-white white-links';
      }
      if(this.paragraph.color.color == '#F8D100') {
        //this.buttonBgClass = 'bg-primary text-white';
      }
    }
  }
  toggleExpand() {
    this.isExpanded = !this.isExpanded;
  }
}
