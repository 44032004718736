<article class="group" *ngIf="!paragraph.tileImage else tileWithImage">
  <ng-container *ngIf="paragraph.tileLink && paragraph.tileLink?.internal">
    <a [routerLink]="paragraph.tileLink.url">
      <ng-container [ngTemplateOutlet]="tileWithoutImage" [ngTemplateOutletContext]="{shouldStripTags: true}"></ng-container>
    </a>
  </ng-container>
  <ng-container *ngIf="paragraph.tileLink &&  !paragraph.tileLink?.internal">
    <a [href]="paragraph.tileLink.url">
      <ng-container [ngTemplateOutlet]="tileWithoutImage" [ngTemplateOutletContext]="{shouldStripTags: true}"></ng-container>
    </a>
  </ng-container>
  <ng-container *ngIf="!paragraph.tileLink">
    <ng-container [ngTemplateOutlet]="tileWithoutImage"></ng-container>
  </ng-container>
</article>

<ng-template #tileWithoutImage let-shouldStripTags="shouldStripTags">
  <div class="head text-center flex items-center min-h-40 rounded-t-2xl px-8 py-10 {{bgClass}}">
    <h2 class="w-full text-3xl font-bold">{{paragraph.title}}</h2>
  </div>
  <div class="card-info py-5 px-4 lg:py-10 lg:px-8 border-tertiary-default border border-t-0 min-h-80">
    <div class="block">
    <span [innerHTML]="paragraph.tileContent?.processed | stripTags" *ngIf="shouldStripTags"></span>
    <span [innerHTML]="paragraph.tileContent?.processed | stripTags" *ngIf="!shouldStripTags"></span>
    </div>
    <span class="bottom-arrow mt-4 group-hover:underline inline-flex items-center group-focus:outline outline-offset-2 font-bold" *ngIf="paragraph.tileLink">
          <svg class="mr-2" width="40" height="40" viewBox="0 0 40 40" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path d="M20 12L17.2 14.8L20.4 18L12 18L12 22L20.4 22L17.2 25.2L20 28L28 20L20 12ZM20 -1.74846e-06C22.7667 -1.50659e-06 25.3667 0.524996 27.8 1.575C30.2333 2.625 32.35 4.05 34.15 5.85C35.95 7.65 37.375 9.76667 38.425 12.2C39.475 14.6333 40 17.2333 40 20C40 22.7667 39.475 25.3667 38.425 27.8C37.375 30.2333 35.95 32.35 34.15 34.15C32.35 35.95 30.2333 37.375 27.8 38.425C25.3667 39.475 22.7667 40 20 40C17.2333 40 14.6333 39.475 12.2 38.425C9.76667 37.375 7.65 35.95 5.85 34.15C4.05 32.35 2.625 30.2333 1.575 27.8C0.524999 25.3667 1.50659e-06 22.7667 1.74846e-06 20C1.99033e-06 17.2333 0.525 14.6333 1.575 12.2C2.625 9.76666 4.05 7.64999 5.85 5.85C7.65 4.05 9.76667 2.625 12.2 1.575C14.6333 0.524995 17.2333 -1.99033e-06 20 -1.74846e-06ZM20 4C15.5333 4 11.75 5.55 8.65 8.65C5.55 11.75 4 15.5333 4 20C4 24.4667 5.55 28.25 8.65 31.35C11.75 34.45 15.5333 36 20 36C24.4667 36 28.25 34.45 31.35 31.35C34.45 28.25 36 24.4667 36 20C36 15.5333 34.45 11.75 31.35 8.65C28.25 5.55 24.4667 4 20 4Z" fill="#481249"/>
          </svg> {{paragraph.tileLink.title}}
        </span>
  </div>
</ng-template>

<ng-template #tileWithImage>
  <article class="card">
    <a [href]="paragraph.tileLink.url" class="group">
      <ng-container *ngIf="paragraph.tileImage?.mediaImage?.variations">
        <ng-container *ngIf="visual; else noVisualTemplate">
          <div class="aspect-ratio-container {{bgClass}}" *ngIf="paragraph.tileImage.mediaImage?.variations?.[0]?.url">
            <img [src]="paragraph.tileImage.mediaImage?.variations?.[0]?.url" class="generated-image">
          </div>
        </ng-container>
        <ng-template #noVisualTemplate>
          <img [src]="paragraph.tileImage.mediaImage?.variations?.[0]?.url" class="w-full rounded-t-2xl card-image" *ngIf="paragraph.tileImage.mediaImage?.variations?.[0]?.url">
        </ng-template>
      </ng-container>
      <div class="card-info py-4 px-4 lg:py-8 lg:px-8 {{bgClass}} text-primary min-h-64">
        <h2 class="text-3xl font-bold mb-2">{{paragraph.title}}</h2>
        <div [innerHTML]="paragraph.tileContent?.processed | stripTags: true" class="mb-16"></div>
        <span class="bottom-arrow group-hover:underline inline-flex items-center group-focus:outline outline-offset-2 object-bottom font-bold break-all">
          <svg class="mr-2" width="40" height="40" viewBox="0 0 40 40" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path d="M20 12L17.2 14.8L20.4 18L12 18L12 22L20.4 22L17.2 25.2L20 28L28 20L20 12ZM20 -1.74846e-06C22.7667 -1.50659e-06 25.3667 0.524996 27.8 1.575C30.2333 2.625 32.35 4.05 34.15 5.85C35.95 7.65 37.375 9.76667 38.425 12.2C39.475 14.6333 40 17.2333 40 20C40 22.7667 39.475 25.3667 38.425 27.8C37.375 30.2333 35.95 32.35 34.15 34.15C32.35 35.95 30.2333 37.375 27.8 38.425C25.3667 39.475 22.7667 40 20 40C17.2333 40 14.6333 39.475 12.2 38.425C9.76667 37.375 7.65 35.95 5.85 34.15C4.05 32.35 2.625 30.2333 1.575 27.8C0.524999 25.3667 1.50659e-06 22.7667 1.74846e-06 20C1.99033e-06 17.2333 0.525 14.6333 1.575 12.2C2.625 9.76666 4.05 7.64999 5.85 5.85C7.65 4.05 9.76667 2.625 12.2 1.575C14.6333 0.524995 17.2333 -1.99033e-06 20 -1.74846e-06ZM20 4C15.5333 4 11.75 5.55 8.65 8.65C5.55 11.75 4 15.5333 4 20C4 24.4667 5.55 28.25 8.65 31.35C11.75 34.45 15.5333 36 20 36C24.4667 36 28.25 34.45 31.35 31.35C34.45 28.25 36 24.4667 36 20C36 15.5333 34.45 11.75 31.35 8.65C28.25 5.55 24.4667 4 20 4Z" fill="#481249"/>
          </svg> {{paragraph.tileLink.title}}
        </span>
      </div>
    </a>
  </article>
</ng-template>
