import {Component, Input, OnInit} from '@angular/core';
import {DrupalMedia} from "../../../models/media.model";
import {TextContentComponent} from "../../text-content/text-content.component";
import {DownloadParagraphComponent} from "../../paragraphs/download-paragraph/download-paragraph.component";
import {BytesPipe} from "../../../pipes/bytes.pipe";
import {MimePipe} from "../../../pipes/mime.pipe";
import {NgForOf, NgIf, NgTemplateOutlet} from "@angular/common";
import {BreadCrumb} from "../../../models/node.model";
import {BreadcrumbsComponent} from "../../breadcrumbs/breadcrumbs.component";


@Component({
  selector: 'app-document-media',
  standalone: true,
  imports: [
    TextContentComponent,
    DownloadParagraphComponent,
    BytesPipe,
    MimePipe,
    NgIf,
    NgTemplateOutlet,
    NgForOf,
    BreadcrumbsComponent
  ],
  templateUrl: './document-media.component.html',
  styleUrl: './document-media.component.scss'
})
export class DocumentMediaComponent implements OnInit{
  @Input() media!: DrupalMedia;
  @Input() breadcrumbs!: Array<BreadCrumb>;

  ngOnInit() {
    //console.log('breadcrumbs', this.breadcrumbs)
  }


}
