<div class="breadcrumbs mt-4" *ngIf="breadcrumbs && breadcrumbs.length >= 2">
  <ol itemscope itemtype="http://schema.org/BreadcrumbList">
    <li *ngFor="let breadcrumb of breadcrumbs; let i = index; let first = first; let last = last;"
        itemprop="itemListElement" itemscope
        itemtype="http://schema.org/ListItem">

      <meta itemprop="position" [content]="i + 1"/>
      <ng-container *ngIf="!last">
        <a itemtype="http://schema.org/Thing" itemprop="item" [routerLink]="breadcrumb.url"
           class="hover:underline focus:outline">
          <span class="breadcrumb-label" itemprop="name">
            <span *ngIf="first">
              <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6.5 19H9.5V13H15.5V19H18.5V10L12.5 5.5L6.5 10V19ZM4.5 21V9L12.5 3L20.5 9V21H13.5V15H11.5V21H4.5Z"
                  fill="#5E2A5A"/>
              </svg>
              <span class="sr-only">Home</span>
            </span>
            <span *ngIf="!first">{{ breadcrumb.title }}</span>
          </span>
        </a>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="mx-4">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M7.29289 14.7071C6.90237 14.3166 6.90237 13.6834 7.29289 13.2929L10.5858 10L7.29289 6.70711C6.90237 6.31658 6.90237 5.68342 7.29289 5.29289C7.68342 4.90237 8.31658 4.90237 8.70711 5.29289L12.7071 9.29289C13.0976 9.68342 13.0976 10.3166 12.7071 10.7071L8.70711 14.7071C8.31658 15.0976 7.68342 15.0976 7.29289 14.7071Z"
                fill="#9CA3AF"/>
        </svg>
      </ng-container>
      <ng-container *ngIf="last">
        <span itemtype="http://schema.org/Thing" itemprop="item">
        <span class="breadcrumb-label" itemprop="name">
            <span *ngIf="first">
              <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6.5 19H9.5V13H15.5V19H18.5V10L12.5 5.5L6.5 10V19ZM4.5 21V9L12.5 3L20.5 9V21H13.5V15H11.5V21H4.5Z"
                  fill="#5E2A5A"/>
              </svg>
              <span class="sr-only">Home</span>
            </span>
            <span *ngIf="!first">{{ breadcrumb.title }}</span>
          </span>
        </span>
      </ng-container>
    </li>
  </ol>
</div>
