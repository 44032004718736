import {Directive, HostListener} from '@angular/core';

@Directive({
  selector: '[appAnchorScrollDirective]',
  standalone: true
})
export class AnchorScrollDirectiveDirective {

  constructor() { }
  @HostListener('click', ['$event']) /* note args array */
  onClick(event: any) {
    if(event.target.href) {
      const href = event.target.href.substring(window.location.origin.length + 1);
      //console.log('href');
      if(href.startsWith('#')) {
        event.preventDefault();
        const element = document.querySelector(href);
        if(element) {
          element.scrollIntoView();
          //window.scrollTo(element);
        }
      }
    }
  }
}
