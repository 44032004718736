<ng-container *ngIf="node">
  <div class="node text-primary" id="main-content">
    <app-news-node *ngIf="node.__typename == 'NodeNews'" [node]="node" [breadcrumbs]="breadcrumbs"></app-news-node>
    <app-theme-node *ngIf="node.__typename == 'NodeThemePage'" [node]="node"
                    [breadcrumbs]="breadcrumbs"></app-theme-node>
    <app-interview-node *ngIf="node.__typename == 'NodeInterview'" [node]="node"
                        [breadcrumbs]="breadcrumbs"></app-interview-node>
    <app-page-node *ngIf="node.__typename == 'NodePage'" [node]="node" [breadcrumbs]="breadcrumbs"></app-page-node>
    <app-event-node *ngIf="node.__typename == 'NodeEvent'" [node]="node" [breadcrumbs]="breadcrumbs"></app-event-node>
    <app-overview-node *ngIf="node.__typename == 'NodeOverviewPage'" [node]="node"></app-overview-node>
  </div>
</ng-container>
<ng-container *ngIf="media">
  <div class="media text-primary" id="main-content">
    <app-document-media *ngIf="media.__typename == 'MediaDocument'" [media]="media"
                        [breadcrumbs]="breadcrumbs"></app-document-media>
  </div>
</ng-container>
