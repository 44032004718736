import {AfterViewInit, Directive, ElementRef, Inject, OnDestroy, PLATFORM_ID} from '@angular/core';
import {IFrameComponent, iframeResizer} from 'iframe-resizer';
import {isPlatformBrowser} from "@angular/common";

@Directive({
  standalone: true,
  selector: '[appIframeResizer]'
})
export class IFrameResizerDirective implements AfterViewInit, OnDestroy {
  component!: IFrameComponent | null;

  constructor(public element: ElementRef, @Inject(PLATFORM_ID) private platformId: object) {
  }

  ngAfterViewInit() {

    if(isPlatformBrowser(this.platformId)) {
      const components = iframeResizer({
        checkOrigin: false,
        heightCalculationMethod: 'documentElementOffset',
        log: false
      }, this.element.nativeElement);

      /* save component reference so we can close it later */
      this.component = components && components.length > 0 ? components[0] : null;
    }
  }

  ngOnDestroy(): void {
    if (this.component && this.component.iFrameResizer) {
      this.component.iFrameResizer.close();
    }
  }
}
