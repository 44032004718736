<div id="paragraph-{{paragraph.id}}" class="paragraph--cta mx-auto" [ngClass]="{'container-xl mx-auto gap-4': withContainer}">
  <div class="grid grid-cols-2" *ngIf="paragraph.ctaImage?.mediaImage else noImage">
    <div class="col-span-2 lg:col-span-1" [ngClass]="{'order-1': paragraph.imagePosition == 'right'}">
      <img *ngIf="paragraph.ctaImage?.mediaImage?.variations?.[0]?.url" src="{{paragraph.ctaImage?.mediaImage?.variations?.[0]?.url}}"
           alt="{{paragraph.ctaImage?.mediaImage?.alt}}" loading="lazy" class="h-full w-full object-cover">
    </div>
    <div class="cta--content flex items-center justify-center col-span-2 lg:col-span-1 {{bgClass}} py-6 px-7 lg:py-24 lg:px-28" [ngClass]="{'order-0': paragraph.imagePosition == 'right'}">
      <div class="rounded border py-4 px-3 lg:py-16 lg:px-12" [ngClass]="borderClass">
        <h2 *ngIf="paragraph.title">{{paragraph.title}}</h2>
        <div class="cta--text-content text-xl pt-2 lg:text-2xl lg:pt-4" [innerHTML]="paragraph.ctaContent?.processed"></div>
        <a *ngIf="paragraph.link && paragraph.link.internal" [routerLink]="paragraph.link.url" class="text-primary {{buttonBgClass}} inline-flex py-4 px-12 rounded-md font-bold mt-10 cta-button">
          {{paragraph.link.title}}
        </a>
        <a *ngIf="paragraph.link && !paragraph.link.internal" [href]="paragraph.link.url" target="_blank" class="text-primary {{buttonBgClass}} inline-flex py-4 px-12 rounded-md font-bold mt-10 cta-button">
          {{paragraph.link.title}}
        </a>
      </div>
    </div>
  </div>
  <ng-template #noImage>
    <div class="{{bgClass}} py-6 px-7 lg:py-24 lg:px-28">
      <div class="cta--content rounded border py-4 px-3 lg:py-16 lg:px-12" [ngClass]="borderClass">
        <h2 *ngIf="paragraph.title">{{paragraph.title}}</h2>
        <div class="cta--text-content text-xl pt-2 lg:text-2xl lg:pt-4" [innerHTML]="paragraph.ctaContent?.processed"></div>
        <a *ngIf="paragraph.link" [routerLink]="paragraph.link.url" class="text-primary {{buttonBgClass}} inline-flex py-4 px-12 rounded-md font-bold mt-10 cta-button">
          {{paragraph.link.title}}
        </a>
      </div>
    </div>
  </ng-template>
</div>
