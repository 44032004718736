<div class="bg-primary text-white px-9 pt-20 pb-[72px] rounded-2xl text-center">
  <p class="text-2xl font-bold tracking-wide mb-5">Ook op de<br/> hoogte blijven?</p>
  <p>Als je geïnteresseerd bent in de gezondheid van leerlingen, dan is dit de nieuwsbrief voor jou.</p>
  <a href="#" class="text-primary bg-secondary inline-flex py-4 px-12 rounded-md font-bold mt-10">
    <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.7143 12L7.4375 7.4L8.73912 6L14.3175 12L8.73912 18L7.4375 16.6L11.7143 12Z" fill="#481249"/>
    </svg> Aanmelden
  </a>
  <p class="text-sm mt-4 opacity-70">De laatste ontwikkelingen, direct in je inbox!</p>
</div>
