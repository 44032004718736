import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripTags',
  standalone: true
})
export class StripTagsPipe implements PipeTransform {

  transform(value: string | undefined, stripOnlyAnchors: boolean = false): string {
    if(value) {
      console.log('striptags');
      if(!stripOnlyAnchors) {
        return value.replace(/<[^>]*>/g, '');
      }
      if(stripOnlyAnchors) {
        return value.replace(/<a[^>]*>(.*?)<\/a>/g, '');
      }
    }
    return '';
  }

}
