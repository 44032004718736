<header class="bg-primary">
  <div class="lg:container lg:mx-auto">
    <div class="lg:flex relative">
      <span class="logo-region relative">
        <a routerLink="/">
          <img src="../../../../assets/img/logo.svg" alt="Logo Gezonde School" height="131" width="326">
        </a>
      </span>
      <!--<button data-collapse-toggle="navbar-default" type="button"
              class="absolute top-2 right-2 inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              aria-controls="navbar-default" aria-expanded="false">
        <span class="sr-only">Open main menu</span>
        <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M33.25 9.5L33.25 12.6667L12.6667 12.6667L12.6667 9.5L33.25 9.5ZM6.96667 11.0833L14.8833 19L6.96667 26.9167L4.75 24.7L10.45 19L4.75 13.3L6.96667 11.0833ZM33.25 17.4167L33.25 20.5833L17.4167 20.5833L17.4167 17.4167L33.25 17.4167ZM33.25 25.3333L33.25 28.5L12.6667 28.5L12.6667 25.3333L33.25 25.3333Z" fill="#F8D100"/>
        </svg>
      </button>-->
      <div id="main-navigation" class="lg:self-end">
        <div class="w-full block lg:w-auto relative top-4" id="navbar-default">
          <app-menu menuName="MAIN"></app-menu>
        </div>
      </div>
      <div class="absolute top-3 right-0 hidden lg:block">
        <a [routerLink]="'/login'" target="_blank" class="border-secondary border py-4 px-6 inline-flex items-center rounded-lg text-white font-bold">
          Log in
          <svg class="ml-2" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 21V19H19V5H12V3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H12ZM10 17L8.625 15.55L11.175 13H3V11H11.175L8.625 8.45L10 7L15 12L10 17Z" fill="#F8D100"/>
          </svg></a>
      </div>
    </div>
    <div class="hidden md:flex justify-center py-7 mt-5">
      <!-- TODO: Probably replace with a component -->
      <!-- Formulier met zoekinvoer en knop -->
      <form class="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 sm:space-x-0" action="/zoeken" method="GET">
        <!-- Zoekicoon -->
        <div class="relative w-full sm:w-auto">
          <span class="absolute inset-y-0 left-0 flex items-center pl-3">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19.6 21L13.3 14.7C12.8 15.1 12.225 15.4167 11.575 15.65C10.925 15.8833 10.2333 16 9.5 16C7.68333 16 6.14583 15.3708 4.8875 14.1125C3.62917 12.8542 3 11.3167 3 9.5C3 7.68333 3.62917 6.14583 4.8875 4.8875C6.14583 3.62917 7.68333 3 9.5 3C11.3167 3 12.8542 3.62917 14.1125 4.8875C15.3708 6.14583 16 7.68333 16 9.5C16 10.2333 15.8833 10.925 15.65 11.575C15.4167 12.225 15.1 12.8 14.7 13.3L21 19.6L19.6 21ZM9.5 14C10.75 14 11.8125 13.5625 12.6875 12.6875C13.5625 11.8125 14 10.75 14 9.5C14 8.25 13.5625 7.1875 12.6875 6.3125C11.8125 5.4375 10.75 5 9.5 5C8.25 5 7.1875 5.4375 6.3125 6.3125C5.4375 7.1875 5 8.25 5 9.5C5 10.75 5.4375 11.8125 6.3125 12.6875C7.1875 13.5625 8.25 14 9.5 14Z" fill="#481249"/>
            </svg>
          </span>
          <!-- Zoekinvoerveld -->
        <input type="search" name="local_ggn_algolia[query]" placeholder="Typ hier wat je wilt vinden"
                class="w-full sm:w-[500px] h-[54px] pl-10 pr-3 py-0 rounded-l-lg text-primary leading-8 m-0">
        </div>
        <!-- Zoekknop -->
          <button class="w-full sm:w-[185px] h-[54px] text-primary font-bold relative custom-left-minus-1px"
          style="background-image: url('data:image/svg+xml;utf8,<svg width=&#34;185&#34; height=&#34;54&#34; viewBox=&#34;0 0 185 54&#34; fill=&#34;none&#34; xmlns=&#34;http://www.w3.org/2000/svg&#34;><path d=&#34;M0 0h158c4 0 6 2 6 2l21 23c0 1-1 2-1 2l-21 24c0 0-2 2-6 2H0V0z&#34; fill=&#34;%23F8D100&#34;/></svg>');">
          <span class="flex items-center pl-0">
          <svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-0 ml-3">
          <path d="M14.0852 12L8.94287 7.4L10.5079 6L17.2153 12L10.5079 18L8.94287 16.6L14.0852 12Z" fill="#481249"/>
          </svg>
            Zoek op de site
          </span>
          </button>
      </form>
  </div>
  </div>
</header>
<div
  class="header-footer-bar"
  *ngIf="isRouterInitialized && !isHomePage">
</div>
