import {Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {ReplaySubject, Subscription} from "rxjs";
import {CardData, TaxonomyTerm} from "../../../../models/node.model";
import {GraphQLService} from "../../../../services/graph-ql.service";
import {TaxonomyService} from "../../../../services/taxonomy.service";
import {AsyncPipe, isPlatformBrowser, NgForOf, NgIf, SlicePipe} from "@angular/common";
import {CardWithImageComponent} from "../../../paragraphs/card-with-image/card-with-image.component";
import {NgxPaginationModule} from "ngx-pagination";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterLink} from "@angular/router";
import {EventCardComponent} from "../../event-node/event-card/event-card.component";

@Component({
  selector: 'app-event-overview',
  standalone: true,
  imports: [
    CardWithImageComponent,
    NgForOf,
    NgIf,
    NgxPaginationModule,
    ReactiveFormsModule,
    RouterLink,
    SlicePipe,
    FormsModule,
    EventCardComponent,
    AsyncPipe
  ],
  templateUrl: './event-overview.component.html',
  styleUrl: './event-overview.component.scss'
})
export class EventOverviewComponent implements OnInit, OnDestroy {
  private isLoadingSubject$ = new ReplaySubject<boolean>();
  public isLoading$ = this.isLoadingSubject$.asObservable();

  @Input() compact = false;

  subscription = new Subscription();

  educationTerms!: Array<TaxonomyTerm>;
  subjectTerms!: Array<TaxonomyTerm>;

  eventNodes!: Array<CardData>;
  filteredNewsNodes!: Array<CardData>;

  @Input() limit:number | null = null;
  @Input() noPager = false;
  p = 1;
  itemsPerPage = 12;
  total: number = 0;
  offset = 0;
  pageInfo: any;

  constructor(private graphQLService: GraphQLService, @Inject(PLATFORM_ID) private platformId: object, private taxonomyService: TaxonomyService) {
  }

  ngOnInit() {
    if(!this.compact && !this.limit) {
      this.getTerms();
    }
    if(this.compact || this.limit) {
      this.itemsPerPage = this.limit? this.limit :8;
    }
    this.getNewsNodes();
  }


  getTerms() {
    const termsSub = this.taxonomyService.getTermsByVocabulary(['"education_type"', '"subject"']).subscribe(result => {

      if (result.data.graphqlTaxonomiesGraphql1.results) {
        const terms = result.data.graphqlTaxonomiesGraphql1.results;
        //console.log('terms', terms);
        const educationTerms = terms.filter(function (el: TaxonomyTerm) {
          return el.__typename == 'TermEducationType';
        });
        //console.log(educationTerms)

        const subjectTerms = terms.filter(function (el: TaxonomyTerm) {
          return el.__typename == 'TermSubject';
        });

        this.educationTerms = educationTerms.map((item: TaxonomyTerm) =>
          Object.assign({}, item, {selected: false})
        );

        this.subjectTerms = subjectTerms.map((item: TaxonomyTerm) =>
          Object.assign({}, item, {selected: false})
        );

      }
    });
    this.subscription.add(termsSub);
  }

  filterChanged(event: any) {
    let educationIds: any = null;
    if (this.educationTerms) {
      this.educationTerms.forEach(term => {
        if (term.selected) {
          if(educationIds) {
            educationIds.push(""+term.id)
          } else {
            educationIds = [""+term.id];
          }
        }
      });
    }
    let subjectIds: any = null;
    if (this.subjectTerms) {
      this.subjectTerms.forEach(term => {
        if (term.selected) {
          if(subjectIds) {
            subjectIds.push(""+term.id)
          } else {
            subjectIds = [""+term.id];
          }
        }
      });
    }
    this.getNewsNodes(educationIds, subjectIds)
  }

  getNewsNodes(educationIds:any = null, subjectIds:any = null): void {
    this.isLoadingSubject$.next(true);

    const sub = this.graphQLService.getEventOverviewNodes(this.offset, this.itemsPerPage, educationIds, subjectIds).subscribe(data => {
      //this.courseCardItems = data.data.getNodes;
      if (data.data.genericNodesListGraph.results) {
        this.isLoadingSubject$.next(false);
        this.pageInfo = data.data.genericNodesListGraph.pageInfo;
        this.eventNodes = data.data.genericNodesListGraph.results;
        this.filteredNewsNodes = this.eventNodes;
        this.total = this.pageInfo.total;
        //console.log(this.eventNodes);
      }
    });
    this.subscription.add(sub);
  }

  pageChange(page: any) {
    this.p = page;
    if (page !== 1) {
      this.offset = this.itemsPerPage * (page - 1);
    } else {
      this.offset = 0;
    }
    this.filterChanged(null);
    if (isPlatformBrowser(this.platformId)) {
      // @ts-ignore
      document.querySelector('#news-overview').scrollIntoView({
        behavior: 'smooth'
      });
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
