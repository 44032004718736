<div id="paragraph-{{paragraph.id}}" [ngClass]="{'container mx-auto gap-4': withContainer}">
  <h2 *ngIf="paragraph.title" class="download-title">{{ paragraph.title }}</h2>
  <div *ngIf="paragraph.files">
    <ng-container *ngFor="let file of paragraph.files">
      <ng-container *ngIf="file.__typename == 'MediaDocument' && file.mediaFile" [ngTemplateOutlet]="downloadButton"
                    [ngTemplateOutletContext]="{file: file, href: environment.drupalUrl + '/media/' + file.id + '/download'}">
      </ng-container>
      <ng-container *ngIf="file.__typename == 'MediaImage' && file.mediaImage" [ngTemplateOutlet]="downloadButton"
                    [ngTemplateOutletContext]="{file: file, href: environment.drupalUrl + '/media/' + file.id + '/download'}">
      </ng-container>
    </ng-container>
  </div>
</div>
<ng-template #downloadButton let-file='file' let-href='href'>
  <div class="block">
    <a [href]="href" download=""
       class="border border-[#C9E9FB] inline-flex rounded-lg p-3 items-center inline-block mb-4" target="_blank">
      <div class="p-3 bg-primary rounded-md mr-4">
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-9 w-9">
          <path
            d="M18 24L10.5 16.5L12.6 14.325L16.5 18.225V6H19.5V18.225L23.4 14.325L25.5 16.5L18 24ZM9 30C8.175 30 7.46875 29.7063 6.88125 29.1188C6.29375 28.5312 6 27.825 6 27V22.5H9V27H27V22.5H30V27C30 27.825 29.7063 28.5312 29.1188 29.1188C28.5312 29.7063 27.825 30 27 30H9Z"
            fill="#F8D100"/>
        </svg>
      </div>
      <div class="flex flex-col">
        <span class="mb-1">Download document</span>
        <span class="text-[#6A458B]">{{ file.name }}</span>
      </div>
    </a>
  </div>
</ng-template>
