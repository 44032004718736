import { Pipe, PipeTransform } from '@angular/core';
import mime from 'mime-types'
@Pipe({
  name: 'mime',
  standalone: true
})
export class MimePipe implements PipeTransform {

  transform(value: string|undefined, conversion: string): unknown {
    if(value) {
      switch (conversion) {
        case 'extension':
          return mime.extension(value);
          break;
      }
    }
    return null;
  }

}
