<!-- <div id="paragraph-{{paragraph.id}}" class="paragraph--columns {{bgClass}}" [ngClass]="{'text-white': isWhiteText}">
  <div [ngClass]="{'py-28': bgClass != '', 'container': withContainer}">
  <h2 class="inline-flex items-center {{titleColorClass}} mb-8 text-3xl font-bold" *ngIf="paragraph.title"><svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-6" *ngIf="paragraph.color?.color">
    <path d="M30.4398 14.3631L25.3969 1.34948C25.3969 1.34948 25.042 0 24.0664 0L17.6287 0.00157657L1.18861 0.00553411C0.534442 0.00553411 0.00426998 0.628582 0.00426998 1.39739L0.00320316 8.58506L0.00106683 21.4215L0 28.6048C0 29.3735 0.530281 29.9966 1.18437 29.9966L17.628 29.999L24.0664 30C25.0124 30 25.4419 28.6487 25.4419 28.6487L30.4339 15.7623C30.4339 15.7623 30.5769 15.4032 30.5769 15.0546C30.5769 14.6995 30.4398 14.3631 30.4398 14.3631Z" fill="white"/>
  </svg> {{paragraph.title}}</h2>
    <div class="grid grid-cols-12 gap-10 mb-10">
      <div class="col-span-12 {{columnClasses}}" *ngFor="let nestedParagraph of paragraph.paragraphs">
        <app-paragraphs [paragraphs]="[nestedParagraph]" [withContainer]="false"></app-paragraphs>
      </div>
    </div>
  </div>
</div> -->

<div id="paragraph-{{paragraph.id}}" class="paragraph--columns {{bgClass}}" [ngClass]="{'text-white': isWhiteText}">
  <div [ngClass]="{
    'pt-10 pb-4': bgClass !== '', 
    'lg:py-28': bgClass !== '', 
    'container': withContainer
  }">
    <h2 class="inline-flex items-center {{titleColorClass}} mb-8 text-3xl font-bold" *ngIf="paragraph.title">
      <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-6" *ngIf="paragraph.color?.color">
        <path d="M30.4398 14.3631L25.3969 1.34948C25.3969 1.34948 25.042 0 24.0664 0L17.6287 0.00157657L1.18861 0.00553411C0.534442 0.00553411 0.00426998 0.628582 0.00426998 1.39739L0.00320316 8.58506L0.00106683 21.4215L0 28.6048C0 29.3735 0.530281 29.9966 1.18437 29.9966L17.628 29.999L24.0664 30C25.0124 30 25.4419 28.6487 25.4419 28.6487L30.4339 15.7623C30.4339 15.7623 30.5769 15.4032 30.5769 15.0546C30.5769 14.6995 30.4398 14.3631 30.4398 14.3631Z" fill="white"/>
      </svg> 
      {{paragraph.title}}
    </h2>
    <div class="grid grid-cols-12 gap-8 mb-10">
      <div class="col-span-12 {{columnClasses}}" *ngFor="let nestedParagraph of paragraph.paragraphs">
        <app-paragraphs [paragraphs]="[nestedParagraph]" [withContainer]="false"></app-paragraphs>
      </div>
    </div>
  </div>
</div>
