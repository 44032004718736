
<div class="bg-tertiary-light rounded-lg py-2 pr-1 pl-6 inline-flex items-center mt-10 mb-24">
  <span class="mr-2">Deel deze pagina op</span> 
  <!-- AddToAny BEGIN -->
  <div class="a2a_kit a2a_kit_size_32 a2a_default_style">
    <span class="button_share"></span>
    <a class="a2a_button a2a_button_x"></a>
    <a class="a2a_button a2a_button_linkedin"></a>
    <a class="a2a_button a2a_button_facebook"></a>
    <a class="a2a_button a2a_button_whatsapp"></a>
    <a class="a2a_button a2a_button_email"></a>
  </div>
  <!-- AddToAny END -->
</div>