import {Component, Inject, PLATFORM_ID} from '@angular/core';
import {InstantSearchService} from '../../../../services/instant-search.service';
import {BaseHit, Hit} from 'instantsearch.js';
import {connectHits, connectRefinementList, connectSearchBox} from 'instantsearch.js/es/connectors';
import {DatePipe, isPlatformBrowser, isPlatformServer, JsonPipe, NgForOf, NgIf} from "@angular/common";
import {of} from "rxjs";
import {clearRefinements, pagination, rangeSlider, refinementList} from "instantsearch.js/es/widgets";
import {RouterLink} from "@angular/router";
import {StripTagsPipe} from "../../../../pipes/strip-tags.pipe";

@Component({
  selector: 'app-search-overview',
  standalone: true,
  templateUrl: './search-overview.component.html',
  styleUrl: './search-overview.component.scss',
  imports: [
    NgForOf,
    DatePipe,
    RouterLink,
    StripTagsPipe,
    NgIf,
    JsonPipe
  ]
})
export class SearchOverviewComponent {
  public hits?: Hit[];
  public query?: string;
  public search?: (value: string) => void;

  constructor(private InstantSearchService: InstantSearchService, @Inject(PLATFORM_ID) private platformId: Object) {
    this.InstantSearchService.addWidgets([
      connectRefinementList(({items, refine}) => {
    })({attribute: 'type'}), connectRefinementList(({items, refine}) => {
    })({attribute: 'created'}),
      connectSearchBox(({refine, query}) => {
        this.search = refine;
        this.query = query;
      })({}),
      connectHits(({hits}) => {
        this.hits = hits;
        console.log('hits', this.hits);
        if(hits[0] && hits[0]._snippetResult) {
          console.log(hits[0]._snippetResult['rendered_item']);
        }
      })({}),
    ]);

    if (isPlatformBrowser(this.platformId)) {
      this.InstantSearchService.addWidgets([
        refinementList({
          container: '#type-list',
          attribute: 'bundle_type_label',
          //searchable: true,
          showMore: true,
          templates: {
            showMoreText(data, { html }) {
              return html`<span>${data.isShowingMore ? 'Toon minder' : 'Toon meer'}</span>`;
            },
          },
        }),
        pagination({
          container:'#pager'
        }),
        refinementList({
          container: '#subject_name-list',
          attribute: 'subject_name',
          //searchable: true,
          showMore: true,
          templates: {
            showMoreText(data, { html }) {
              return html`<span>${data.isShowingMore ? 'Toon minder' : 'Toon meer'}</span>`;
            },
          },
        }),   refinementList({
          container: '#education_name-list',
          attribute: 'education_name',
          //searchable: true,
          showMore: true,
          templates: {
            showMoreText(data, { html }) {
              return html`<span>${data.isShowingMore ? 'Toon minder' : 'Toon meer'}</span>`;
            },
          },
        }),       /* rangeSlider({
          container: '#created-list',
          attribute: 'created',
        }),*/
        clearRefinements({
          container: '#clear-refinements',
          templates: {
            resetLabel({hasRefinements}, {html}) {
              return html`<span>${hasRefinements ? 'Filters wissen' : 'Geen filtes actief'}</span>`;
            },
          }
        }),
      ])
    }

  }

  handleInput(event: Event) {
    if (event.target instanceof HTMLInputElement) {
      this.search!(event.target.value);
    }
  }

  get totalResults(): number {
    return this.hits ? this.hits.length : 0;
  }

  ngAfterContentInit() {
    this.InstantSearchService.start();
  }

  ngOnDestroy() {
    //this.InstantSearchService.dispose();
  }

  protected readonly JSON = JSON;
}
