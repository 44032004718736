import {Component, Input} from '@angular/core';
import {MenuParagraph} from "../../../models/node.model";
import {NgForOf, NgIf, NgTemplateOutlet} from "@angular/common";
import {RouterLink} from "@angular/router";

@Component({
  selector: 'app-menu-paragraph',
  standalone: true,
  imports: [
    NgForOf,
    RouterLink,
    NgIf,
    NgTemplateOutlet
  ],
  templateUrl: './menu-paragraph.component.html',
  styleUrl: './menu-paragraph.component.scss'
})
export class MenuParagraphComponent {
  @Input() withContainer = false;
  @Input() paragraph!: MenuParagraph;

}
