import { Component, Inject } from '@angular/core';
import { MenuComponent } from "../../menu/menu.component";
import { CommonModule } from "@angular/common";
import { Router, RouterModule, NavigationEnd } from "@angular/router";
import { filter } from 'rxjs/operators';
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MenuComponent
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  isHomePage: boolean = false;
  isRouterInitialized: boolean = false;

  constructor(
    @Inject(Router) private router: Router,
  ) {
    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.isHomePage = event.urlAfterRedirects === '/';
      this.isRouterInitialized = true;
    });
  }

  protected readonly environment = environment;
}
