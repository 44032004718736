import { Injectable } from '@angular/core';
import {Apollo, gql} from "apollo-angular";
import {Observable} from "rxjs";
import {ApolloQueryResult} from "@apollo/client/core";

@Injectable({
  providedIn: 'root'
})
export class TaxonomyService {


  constructor(
    private apollo: Apollo,
  ) {
  }

  getTermsByVocabulary(vocabulary: string[]): Observable<ApolloQueryResult<any>> {
    return this.apollo.query({
      query: gql`query TermsByVocabulary {
        graphqlTaxonomiesGraphql1(filter: {vid: [${vocabulary}]}) {
          results {
            __typename
            ... on TermSubject {
              id
              name
            }
            ... on TermEducationType {
              id
              name
            }
          }
        }
      }
      `
    })
  }
}
