import {Component, Inject, OnInit, PLATFORM_ID, AfterViewInit} from '@angular/core';
import {Router} from "@angular/router";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-share-links',
  standalone: true,
  imports: [],
  templateUrl: './share-links.component.html',
  styleUrl: './share-links.component.scss'
})
export class ShareLinksComponent implements OnInit{
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
  }
  ngOnInit() {
    if(isPlatformBrowser(this.platformId)) {
      if(window.a2a) {
        window.a2a.init_all();
      }
    }
  }
  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const svgElements = document.querySelectorAll('.a2a_svg');
      svgElements.forEach(svg => svg.remove());
    }
  }
}
