import { Injectable } from '@angular/core';
import algoliasearch from 'algoliasearch/lite';
import InstantSearch from 'instantsearch.js/es/lib/InstantSearch';
import type {IndexWidget, UnknownWidgetParams, Widget} from 'instantsearch.js';
import history from 'instantsearch.js/es/lib/routers/history';
import {Router} from "@angular/router";
import {
  SearchBoxConnectorParams,
  SearchBoxWidgetDescription
} from "instantsearch.js/es/connectors/search-box/connectSearchBox";
import {environment} from "../../environments/environment";

const searchClient = algoliasearch(
  'N3MD4T4X6W',
  '2bdde18f9288c9e97ec9035c9424e49c'
);

@Injectable({
  providedIn: 'root',
})
export class InstantSearchService {
  public instantSearchInstance: InstantSearch;


  constructor(router: Router) {
    this.instantSearchInstance = new InstantSearch({
      searchClient,
      indexName: environment.algoliaIndex,
      future: { preserveSharedStateOnUnmount: true },
      routing: {
        router: history({
          getLocation: () => {
            if (typeof window === 'undefined') {
              // no other way to get this in constructor
              return new URL(
                router['location']._locationStrategy._platformLocation.href
              ) as unknown as Location;
            }
            return window.location;
          },
        }),
      },
    });
  }

  dispose() {
    this.instantSearchInstance.dispose();
  }

  start() {
    this.instantSearchInstance.start();
  }

  addWidgets(widgets: Array<Widget | IndexWidget>) {
    this.instantSearchInstance.addWidgets(widgets);
  }

  removeWidgets(widgets: Array<Widget | IndexWidget>) {
    this.instantSearchInstance.removeWidgets(widgets);
  }
}
