<footer class="bg-primary pb-6 pt-14 lg:pt-28">
  <div class="container mx-auto  gap-x-4">

    <app-menu menuName="FOOTER"></app-menu>

  </div>
  <div class="container mx-auto text-center text-[#FEDCFF]">
    &copy;{{ getCurrentYear() }} Gezonde School. All Rights Reserved.
  </div>
</footer>
