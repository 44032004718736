import { Component, Input, OnDestroy, OnInit, Renderer2, ElementRef } from '@angular/core';
import { GraphQLService } from '../../services/graph-ql.service';
import { Subscription } from 'rxjs';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { Menu } from '../../models/menu.model';
import { LowerCasePipe, NgFor, NgForOf, NgIf, CommonModule } from '@angular/common'; 
import { MenubarModule } from 'primeng/menubar';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-menu',
  standalone: true,
  imports: [
    CommonModule, 
    RouterLink,
    NgIf,
    NgFor,
    NgForOf,
    RouterLinkActive,
    MenubarModule,
    LowerCasePipe,
  ],
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss',
})
export class MenuComponent implements OnInit, OnDestroy {
  @Input() display = 'inline';
  @Input() menuName = 'main';
  public menu: Menu | null;
  public processedMenu!: MenuItem[];
  menuOpen = false;

  private subscription = new Subscription();

  constructor(
    private graphQLService: GraphQLService,
    private renderer: Renderer2, 
    private el: ElementRef       
  ) {
    this.menu = null;
  }

  getMenuItemClasses(menuItem: MenuItem): string {
    let classes = '';

    if (menuItem.items && menuItem.items.length > 0) {
      classes += 'menu-item-with-children ';
    }

    if (menuItem.class === 'hidden') {
      classes += 'hidden ';
    } else if (menuItem.class) {
      classes += menuItem.class + ' ';
    }

    return classes.trim();
  }

  ngOnInit(): void {
    const sub = this.graphQLService.getMenu(this.menuName).subscribe((menu) => {
      if (menu.data.menu) {
        this.menu = menu.data.menu;
        this.processItems();
        this.addActiveClassToMenuItem();
        
        if (this.menuName === 'MAIN') {
          this.processedMenu.push({
            label: 'Zoeken',
            url: '/zoeken',
            class: 'md:hidden mb-zoek',
          });
        }
      }
    });
    this.subscription.add(sub);
  }  

  processItems() {
    if (this.menu?.items) {
      this.menu.items.forEach((item) => {
        let children: MenuItem[] | undefined;
        if (item.children) {
          children = item.children.map((child) => ({
            label: child.title,
            routerLink: child.internal ? child.url : undefined,
            url: !child.internal ? child.url : undefined,
            class: child.title === 'Ontoegankelijk' ? 'hidden' : '',
          }));
        } else {
          children = undefined;
        }

        let processedMenuItem: MenuItem = {
          label: item.title,
          routerLink: item.internal ? item.url : undefined,
          url: !item.internal ? item.url : undefined,
          items: children,
          class: item.title === 'Ontoegankelijk' ? 'hidden' : '',
        };

        if (this.processedMenu) {
          this.processedMenu.push(processedMenuItem);
        } else {
          this.processedMenu = [processedMenuItem];
        }
      });
    }
  }

  addActiveClassToMenuItem(): void {
    const menuItems = this.el.nativeElement.querySelectorAll('li.p-menuitem');
    menuItems.forEach((item: HTMLElement) => {
      const isActive = item.getAttribute('aria-expanded') === 'true'; 
      if (isActive) {
        this.renderer.addClass(item, 'p-highlight');
        this.renderer.addClass(item, 'p-menuitem-active');
      }
    });
  }

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }
  
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
