import {Component, Input, OnInit} from '@angular/core';
import {CardData} from "../../../models/node.model";
import {DatePipe, NgClass, NgForOf, NgIf, NgTemplateOutlet} from "@angular/common";
import {ReadTimeComponent} from "../../read-time/read-time.component";
import {RouterLink} from "@angular/router";
import {StripTagsPipe} from "../../../pipes/strip-tags.pipe";

@Component({
  selector: 'app-card-with-image',
  standalone: true,
  imports: [
    DatePipe,
    NgIf,
    ReadTimeComponent,
    RouterLink,
    NgTemplateOutlet,
    NgClass,
    NgForOf,
    StripTagsPipe
  ],
  templateUrl: './card-with-image.component.html',
  styleUrl: './card-with-image.component.scss'
})
export class CardWithImageComponent implements OnInit{
  @Input() cardData!: CardData;
  @Input() highlighted = false;
  @Input() withContainer = true;
  @Input() fallbackImageFromHeader?: string;

  imgUrl: string | undefined;
  fallbackImgUrl: string = '/assets/img/hl-header-fallback-image.jpg';
  shortenedText: string | undefined;

  ngOnInit() {
    console.log('cardWithImage', this.cardData);
  
    if (this.cardData.image?.mediaImage) {
      this.imgUrl = this.cardData.image?.mediaImage?.variations[0].url;
    }
    if (!this.imgUrl && this.fallbackImageFromHeader) {
      this.imgUrl = this.fallbackImageFromHeader;
    }
    if (!this.imgUrl) {
      this.imgUrl = this.fallbackImgUrl;
    }
  
    const plainText = this.stripTags(this.cardData.body?.processed || '');
    this.shortenedText = this.getShortenedText(plainText);
  }  

  private stripTags(html: string): string {
    return html.replace(/<[^>]*>/g, ''); 
  }

  private getShortenedText(text: string, maxLength: number = 260): string {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  }
}
