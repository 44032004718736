import { Routes } from '@angular/router';
import {NodeResolver} from "./services/drupal-node-resolver.service";
import {DrupalEntityComponent} from "./components/drupal-entity/drupal-entity.component";

export const routes: Routes = [

  {
    path: '**',
    component: DrupalEntityComponent,
    resolve: {
      data: NodeResolver
    },
    pathMatch: 'full'
  },
];
