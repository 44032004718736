import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NgIf, NgClass } from "@angular/common";
import { Router } from '@angular/router';
import { Header } from "../../models/node.model";
import { TextContentComponent } from "../text-content/text-content.component";
import { CardWithImageComponent } from "../paragraphs/card-with-image/card-with-image.component";

@Component({
  selector: 'app-header-entity',
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    TextContentComponent,
    CardWithImageComponent
  ],
  templateUrl: './header-entity.component.html',
  styleUrls: ['./header-entity.component.scss']
})
export class HeaderEntityComponent implements OnInit, OnChanges {
  @Input() header!: Header;

  imgUrl: string | undefined;
  headerUrl: string | undefined;
  maskedUrl: string | undefined;
  isHomePage: boolean = false;

  constructor(private router: Router) {}

  ngOnInit() {
    //console.log('this.header', this.header)
    this.updateHeaderImage();
    this.isHomePage = this.router.url === '/';
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['header']) {
      this.updateHeaderImage();
    }
  }

  private updateHeaderImage() {
    //console.log('header', this.header);

    const variations = this.header?.image?.mediaImage?.variations;
    // Reset variables to prevent old image from showing image from previous page on a page without header image
    this.headerUrl = undefined;
    this.imgUrl =  undefined;
    this.maskedUrl = undefined;
    if (variations && variations.length > 0) {
      const headerVariation = variations.find(v => v?.name === 'HEADER');
      const maskedImageVariation = variations.find(v => v?.name === 'MASKED_IMAGE_WITH_OVERLAY');

      this.headerUrl = headerVariation?.url;
      this.maskedUrl = maskedImageVariation?.url;

      this.imgUrl = this.headerUrl;
    }
  }
}
