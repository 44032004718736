import {Component, Input, OnInit} from '@angular/core';
import {NgForOf, NgIf} from "@angular/common";
import {TextContentComponent} from "../text-content/text-content.component";
import {TextParagraphComponent} from "./text-paragraph/text-paragraph.component";
import {QuoteParagraphComponent} from "./quote-paragraph/quote-paragraph.component";
import {ImageParagraphComponent} from "./image-paragraph/image-paragraph.component";
import {ColumnsParagraphComponent} from "./columns-paragraph/columns-paragraph.component";
import {TileParagraphComponent} from "./tile-paragraph/tile-paragraph.component";
import {Paragraph} from "../../models/node.model";
import {LibraryParagraphComponent} from "./library-paragraph/library-paragraph.component";
import {WebformParagraphComponent} from "./webform-paragraph/webform-paragraph.component";
import {DownloadParagraphComponent} from "./download-paragraph/download-paragraph.component";
import {EmbedParagraphComponent} from "./embed-paragraph/embed-paragraph.component";
import {CallToActionParagraphComponent} from "./call-to-action-paragraph/call-to-action-paragraph.component";
import {OverviewParagraphComponent} from "./overview-paragraph/overview-paragraph.component";
import {TextAndImageParagraphComponent} from "./text-and-image-paragraph/text-and-image-paragraph.component";
import {MenuParagraphComponent} from "./menu-paragraph/menu-paragraph.component";
import {TabsParagraphComponent} from "./tabs-paragraph/tabs-paragraph.component";
import {ButtonMenuParagraphComponent} from "./button-menu-paragraph/button-menu-paragraph.component";
import {AccordionParagraphComponent} from "./accordion-paragraph/accordion-paragraph.component";
import {VideoParagraphComponent} from "./video-paragraph/video-paragraph.component";

@Component({
  selector: 'app-paragraphs',
  standalone: true,
  imports: [
    NgIf,
    NgForOf,
    TextContentComponent,
    TextParagraphComponent,
    QuoteParagraphComponent,
    ImageParagraphComponent,
    ColumnsParagraphComponent,
    TileParagraphComponent,
    LibraryParagraphComponent,
    WebformParagraphComponent,
    DownloadParagraphComponent,
    EmbedParagraphComponent,
    CallToActionParagraphComponent,
    OverviewParagraphComponent,
    TextAndImageParagraphComponent,
    MenuParagraphComponent,
    TabsParagraphComponent,
    ButtonMenuParagraphComponent,
    AccordionParagraphComponent,
    VideoParagraphComponent
  ],
  templateUrl: './paragraphs.component.html',
  styleUrl: './paragraphs.component.scss'
})
export class ParagraphsComponent implements OnInit {
  @Input() paragraphs: [Paragraph] | any | undefined;
  @Input() withContainer: boolean = true;
  containerClasses: string = '';
  @Input() bgClass: string = '';
  ngOnInit() {
    console.log('paragraphsComponent paragraphs', this.paragraphs);
    if(this.withContainer) {
      this.containerClasses='container mx-auto gap-4';
    }
  }

}
