import {Component, Inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import {ReplaySubject, Subscription} from "rxjs";
import {GraphQLService} from "../../../../services/graph-ql.service";
import {TaxonomyService} from "../../../../services/taxonomy.service";
import {CardData, TaxonomyTerm} from "../../../../models/node.model";
import {AsyncPipe, DatePipe, isPlatformBrowser, Location, NgForOf, NgIf, SlicePipe} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {NgxPaginationModule} from "ngx-pagination";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {ReadTimeComponent} from "../../../read-time/read-time.component";
import {CardWithImageComponent} from "../../../paragraphs/card-with-image/card-with-image.component";
import {HttpParams} from "@angular/common/http";

@Component({
  selector: 'app-news-overview',
  standalone: true,
  imports: [
    NgIf,
    NgForOf,
    FormsModule,
    NgxPaginationModule,
    RouterLink,
    ReadTimeComponent,
    DatePipe,
    CardWithImageComponent,
    SlicePipe,
    AsyncPipe
  ],
  templateUrl: './news-overview.component.html',
  styleUrl: './news-overview.component.scss'
})
export class NewsOverviewComponent implements OnInit {
  private isLoadingSubject$ = new ReplaySubject<boolean>();
  public isLoading$ = this.isLoadingSubject$.asObservable();

  @Input() compact = false;

  subscription = new Subscription();

  selectedSubjectIds: any = null;
  selectedEducationIds: any = null;

  educationTerms!: Array<TaxonomyTerm>;
  subjectTerms!: Array<TaxonomyTerm>;

  newsNodes!: Array<CardData>;
  filteredNewsNodes!: Array<CardData>;

  @Input() limit: number | null = null;
  @Input() noPager = false;
  p = 1;
  itemsPerPage = 12;
  total: number = 0;
  offset = 0;
  pageInfo: any;
  queryParams: any;

  constructor(private graphQLService: GraphQLService, @Inject(PLATFORM_ID) private platformId: object, private taxonomyService: TaxonomyService,
              private router: Router, private location: Location, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    if (!this.compact && !this.limit) {
      this.getQueryParams();
      this.getTerms();
    }
    if (this.compact || this.limit) {
      this.itemsPerPage = this.limit ? this.limit : 8;
      console.log('this.itemsPerPage', this.itemsPerPage);
    }
    this.getNewsNodes();
  }

  getQueryParams() {

    this.activatedRoute.queryParams.subscribe(params => {
      this.queryParams = params;
      console.log('params', params['subjects']);
      if (params['subjects']) {
        this.selectedSubjectIds = [];
        /// TODO: FIX single value but multi digit properties
        if(Array.isArray(params.subjects)) {
          for (let subject of params.subjects) {
            this.selectedSubjectIds.push(subject)
          }
        } else {
          this.selectedSubjectIds = [params.subjects];
        }
        //sthis.selectedSubjectIds = params.subjects;
      }
      if (params.educations) {
        this.selectedEducationIds = [];
        if(Array.isArray(params.educations)) {
          for (let education of params.educations) {
            this.selectedEducationIds.push(education);
          }
        } else {
          this.selectedEducationIds = [params.educations];
        }
      }
    })
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getTerms() {
    const termsSub = this.taxonomyService.getTermsByVocabulary(['"education_type"', '"subject"']).subscribe(result => {

      if (result.data.graphqlTaxonomiesGraphql1.results) {
        const terms = result.data.graphqlTaxonomiesGraphql1.results;
        console.log('terms', terms);
        const educationTerms = terms.filter(function (el: TaxonomyTerm) {
          return el.__typename == 'TermEducationType';
        });
        console.log(educationTerms)

        const subjectTerms = terms.filter(function (el: TaxonomyTerm) {
          return el.__typename == 'TermSubject';
        });

        this.educationTerms = educationTerms.map((item: TaxonomyTerm) => {
          //
          //Object.assign({}, item, {selected: false})
          if(this.queryParams.educations && this.queryParams.educations.includes(item.id)) {
            return Object.assign({}, item, {selected: true})
          } else {
            return Object.assign({}, item, {selected: false})
          }
        });

        this.subjectTerms = subjectTerms.map((item: TaxonomyTerm) => {
          if(this.queryParams.subjects && this.queryParams.subjects.includes(item.id)) {
            return Object.assign({}, item, {selected: true})
          } else {
            return Object.assign({}, item, {selected: false})
          }
        });



      }
    });
    this.subscription.add(termsSub);
  }

  filterChanged(event: any) {
    // Reset page and offset
    this.p=1;
    this.offset = 0;
    let educationIds: any = null;
    if (this.educationTerms) {
      this.educationTerms.forEach(term => {
        if (term.selected) {
          if (educationIds) {
            educationIds.push("" + term.id)
          } else {
            educationIds = ["" + term.id];
          }
        }
      });
    }
    let subjectIds: any = null;
    if (this.subjectTerms) {
      this.subjectTerms.forEach(term => {
        if (term.selected) {
          if (subjectIds) {
            subjectIds.push("" + term.id)
          } else {
            subjectIds = ["" + term.id];
          }
        }
      });
    }
    this.selectedSubjectIds = subjectIds;
    this.selectedEducationIds = educationIds;
    this.buildQueryParams(subjectIds, educationIds);
    this.getNewsNodes()
  }

  buildQueryParams(subjectIds: any, educationIds: any) {

    let params = new HttpParams();
    let queryParams: any = {};
    //queryParams.subjectId = subjectIds;

    console.log({subjectId: subjectIds, educationId: educationIds})
    if(subjectIds) {
      queryParams['subjects'] = subjectIds;
    }
    if(educationIds) {
      queryParams['educations'] = educationIds
    }

    console.log('params', params.toString())
    const urlTree = this.router.createUrlTree([], {
      queryParams: queryParams,
      queryParamsHandling: 'merge',
      preserveFragment: true
    }).toString()
    this.location.go(urlTree);


  }

  getNewsNodes(educationIds: any = null, subjectIds: any = null): void {
    this.isLoadingSubject$.next(true);

    const sub = this.graphQLService.getNewsOverviewNodes(this.offset, this.itemsPerPage, this.selectedEducationIds, this.selectedSubjectIds).subscribe(data => {
      //this.courseCardItems = data.data.getNodes;
      if (data.data.genericNodesListGraph.results) {
        this.isLoadingSubject$.next(false);
        this.pageInfo = data.data.genericNodesListGraph.pageInfo;
        this.newsNodes = data.data.genericNodesListGraph.results;
        this.filteredNewsNodes = this.newsNodes;
        this.total = this.pageInfo.total + this.pageInfo.offset;
        //console.log(this.newsNodes);
      }
    });
    this.subscription.add(sub);
  }

  pageChange(page: any) {
    this.p = page;
    if (page !== 1) {
      this.offset = this.itemsPerPage * (page - 1);
    } else {
      this.offset = 0;
    }
    this.filterChanged(null);
    if (isPlatformBrowser(this.platformId)) {
      // @ts-ignore
      document.querySelector('#news-overview').scrollIntoView({
        behavior: 'smooth'
      });
    }
  }

}
