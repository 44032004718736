<app-header-entity [header]="node.header" *ngIf="node.header"></app-header-entity>
<div class="container mx-auto" [ngClass]="{'no-aside': !node.sidebarParagraphs && !node.showToc, 'aside': node.sidebarParagraphs || node.showToc}">
  <div class="pt-9 pb-14">
    <app-breadcrumbs [breadcrumbs]="breadcrumbs" *ngIf="breadcrumbs"></app-breadcrumbs>
  </div>
  <div class="">
    <ng-container *ngIf="node.showToc || node.sidebarParagraphs else noSidebar">
      <div class="lg:grid lg:grid-cols-3 lg:gap-x-10">
        <div class="lg:col-span-1" *ngIf="node.sidebarParagraphs">
          <app-paragraphs [paragraphs]="node.sidebarParagraphs"></app-paragraphs>
        </div>
        <div class="lg:col-span-2 mx-auto">
          <div class="">
          <span class="pb-8 block">
              <h1 class="font-bold text-3xl">{{ node.title }}</h1>
              <p class="text-3xl tracking-tighter font-light" *ngIf="node.subtitle">{{ node.subtitle }}</p>
            </span>
            <img *ngIf="node.image && node.image.mediaImage" src="{{node.image.mediaImage.url}}"
                 alt="{{node.image.mediaImage.alt}}" loading="lazy" class="rounded-lg mb-10">
            <div class="text-1xl lg:text-2xl font-bold leading-normal intro" *ngIf="node.body"
                 [innerHTML]="node.body.processed"></div>
            <span class="node--paragraphs">
              <app-paragraphs [paragraphs]="node.paragraphs" *ngIf="node.paragraphs" [withContainer]="false"></app-paragraphs>
            </span>
          </div>
        </div>
        <div class="lg:col-span-1" *ngIf="node.showToc && node.paragraphs">
          <app-table-of-contents [content]="node.paragraphs" *ngIf="node.paragraphs"></app-table-of-contents>
        </div>
      </div>
    </ng-container>
    <ng-template #noSidebar>
      <span class="pb-8 block">
        <h1 class="font-bold text-3xl">{{ node.title }}</h1>
        <p class="text-3xl tracking-tighter font-light" *ngIf="node.subtitle">{{ node.subtitle }}</p>
      </span>
      <img *ngIf="node.image && node.image.mediaImage" src="{{node.image.mediaImage.url}}"
           alt="{{node.image.mediaImage.alt}}" loading="lazy" class="rounded-lg mb-10">
      <div class="text-1xl lg:text-2xl font-bold leading-normal intro" *ngIf="node.body" [innerHTML]="node.body.processed"></div>
      <span class="node--paragraphs">
        <app-paragraphs [paragraphs]="node.paragraphs" *ngIf="node.paragraphs" [withContainer]="false"></app-paragraphs>
      </span>
    </ng-template>

  </div>
  <app-share-links></app-share-links>
</div>
