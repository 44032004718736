import {Component, Inject, OnInit, PLATFORM_ID, Renderer2} from '@angular/core';
import {Router, NavigationEnd, NavigationStart} from '@angular/router';
import {RouterOutlet} from '@angular/router';
import {DrupalEntityComponent} from "./components/drupal-entity/drupal-entity.component";
import {HeaderComponent} from "./components/layout/header/header.component";
import {FooterComponent} from "./components/layout/footer/footer.component";
import {isPlatformBrowser, Location} from "@angular/common";
import {LoadingBarHttpClientModule} from "@ngx-loading-bar/http-client";
import {FlowbiteService} from "./services/flowbite.service";
import {initFlowbite} from "flowbite";
import {PrimeNGConfig} from "primeng/api";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, DrupalEntityComponent, HeaderComponent, FooterComponent, LoadingBarHttpClientModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  title = 'Gezonde School';

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private primengConfig: PrimeNGConfig,
    private renderer: Renderer2,
    private router: Router,private location: Location
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {

      this.router.events.subscribe(event => {
        if(event instanceof NavigationStart || event instanceof NavigationEnd) {
          const urlParamMap: { [key: string]: string } = {
            '/mbo/gezonde-school-activiteiten':
              'eyJzZWxlY3RlZF9maWx0ZXIiOlt7IjljOTc1NmYyLWRiZDAtNGExMC05ZmJjLThkZGYyZjVmNWE1MyI6W3sibGFiZWwiOiJNQk8iLCJ2YWx1ZSI6IjljMDg4YWRlLWIyNGYtNDUxNS05ZmY1LTg3NDAwYTgxNzQ1YiJ9XX1dfQ==',
            '/voortgezet-onderwijs/gezonde-school-activiteiten-vo':
              'eyJzZWxlY3RlZF9maWx0ZXIiOlt7IjljOTc1NmYyLWRiZDAtNGExMC05ZmJjLThkZGYyZjVmNWE1MyI6W3sibGFiZWwiOiJWb29ydGdlemV0IG9uZGVyd2lqcyIsInZhbHVlIjoiOWMwODhhZDMtODdiNy00NzQ3LWFjODItZDZmNThhZGMzYWZiIn1dfV19',
            '/primair-onderwijs/gezonde-school-activiteiten':
              'eyJzZWxlY3RlZF9maWx0ZXIiOlt7IjljOTc1NmYyLWRiZDAtNGExMC05ZmJjLThkZGYyZjVmNWE1MyI6W3sibGFiZWwiOiJQcmltYWlyIE9uZGVyd2lqcyIsInZhbHVlIjoiOWMwODhhYzgtMzBhYy00ZTZjLTg1N2ItNDZiZjRiMTRmOTE1In1dfV19',
            '/gespecialiseerd-onderwijs/gezonde-school-activiteiten':
              'eyJzZWxlY3RlZF9maWx0ZXIiOlt7IjljOTc1NmYyLWRiZDAtNGExMC05ZmJjLThkZGYyZjVmNWE1MyI6W3sibGFiZWwiOiJHZXNwZWNpYWxpc2VlcmQgb25kZXJ3aWpzIiwidmFsdWUiOiI5YzA4OGFlYS1jMzc1LTQxODEtODY4Ni00MjQyZGJiOTExNGYifV19XX0=',
          };

          // Retrieve the database value based on the current URL
          const atabase = urlParamMap[this.router.url];
          if(atabase) {
            const urlTree = this.router.createUrlTree([], {
              queryParams: {atabase},
              queryParamsHandling: 'merge',
              preserveFragment: true
            }).toString()
            this.location.go(urlTree);
          }
        }
        if (event instanceof NavigationEnd) {
          /*console.log('this.router.url', this.router.url)
          if(this.router.url == '/gespecialiseerd-onderwijs/gezonde-school-activiteiten') {
            this.router.navigateByUrl('/gespecialiseerd-onderwijs/gezonde-school-activiteiten?atabase=eyJzZWxlY3RlZF9maWx0ZXIiOlt7IjljOTc1NmYyLWRiZDAtNGExMC05ZmJjLThkZGYyZjVmNWE1MyI6W3sibGFiZWwiOiJHZXNwZWNpYWxpc2VlcmQgb25kZXJ3aWpzIiwidmFsdWUiOiI5YzA4OGFlYS1jMzc1LTQxODEtODY4Ni00MjQyZGJiOTExNGYifV19XX0%3D');
          }*/
          if (this.router.url === '/') {
            this.renderer.addClass(document.body, 'home');
          } else {
            this.renderer.removeClass(document.body, 'home');
          }
        }
      });
    }
  }
}
