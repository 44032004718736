import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {RouterLink} from "@angular/router";
import {NgForOf, NgIf} from "@angular/common";
import {BreadCrumb} from "../../models/node.model";

@Component({
  selector: 'app-breadcrumbs',
  standalone: true,
  templateUrl: './breadcrumbs.component.html',
  imports: [
    RouterLink,
    NgForOf,
    NgIf
  ],
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit, OnChanges {

  constructor() {
  }

  @Input() breadcrumbs: Array<BreadCrumb> = [];
  lastCrumb!: BreadCrumb[];

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {

    //this.lastCrumb = this.breadcrumbs.splice(-1, 1);

  }


}
