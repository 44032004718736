import {Component, ElementRef, Inject, Input, OnInit, PLATFORM_ID, Renderer2, ViewChild} from '@angular/core';
import {WebformParagraph} from "../../../models/node.model";
import {DOCUMENT, isPlatformBrowser, NgClass, NgIf} from "@angular/common";
import {BypassSecurity} from "../../../pipes/bypass-security.pipe";
import {Subscription} from "rxjs";
import {IFrameResizerDirective} from "../../../../directives/IframeResizer.directive";

@Component({
  selector: 'app-webform-paragraph',
  standalone: true,
  imports: [
    NgIf,
    BypassSecurity,
    IFrameResizerDirective,
    NgClass
  ],
  templateUrl: './webform-paragraph.component.html',
  styleUrl: './webform-paragraph.component.scss'
})
export class WebformParagraphComponent implements OnInit {
  @Input() paragraph!: WebformParagraph;
  isBrowser!: boolean;
  iframe!: string;
  objectUrl!: string;
  @ViewChild("formIframe") formIframe!: any;

  @Input() withContainer = true;

  constructor(@Inject(PLATFORM_ID) private platformId: string,
              private el: ElementRef,
              private renderer: Renderer2, @Inject(DOCUMENT) private document: any) {
    if (isPlatformBrowser(this.platformId)) {
      this.isBrowser = true;
    }
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.processEmbed();
    }
  }

  processEmbed() {
    // put your code here that you want to run once preboot is complete
    this.isBrowser = true;
    //iframeResizer({ log: true }, '#formIframe')
    const iframeRegex = new RegExp('<iframe[^>]*><\/iframe>');
    const iframeRegexResult = iframeRegex.exec(this.paragraph.embed);
    if (iframeRegexResult && document) {
      const temp = document.createElement('div');
      // @ts-ignore
      temp.innerHTML = iframeRegexResult;
      const iframe = temp.getElementsByTagName('iframe')[0];
      const htmlObject = temp.firstChild;
      // @ts-ignore
      this.objectUrl = htmlObject?.src;
      // @ts-ignore
      //htmlObject.src = htmlObject.src + '?source=' + this.document.location.hostname;
      // @ts-ignore
      this.iframe = htmlObject.outerHTML;
    }
  }


}
