<ng-container *ngIf="paragraphs">
  <!-- For now this is hardcoded here-->
  <div class="paragraphs " *ngFor="let paragraph of paragraphs">
    <app-text-paragraph *ngIf="paragraph.__typename == 'ParagraphText'" [paragraph]="paragraph" [withContainer]="withContainer"></app-text-paragraph>
    <app-quote-paragraph *ngIf="paragraph.__typename == 'ParagraphQuote'" [paragraph]="paragraph" [withContainer]="withContainer"></app-quote-paragraph>
    <app-image-paragraph *ngIf="paragraph.__typename == 'ParagraphImage'" [paragraph]="paragraph" [withContainer]="withContainer"></app-image-paragraph>
    <app-text-and-image-paragraph *ngIf="paragraph.__typename == 'ParagraphTextAndImage'" [paragraph]="paragraph" [withContainer]="withContainer"></app-text-and-image-paragraph>
    <app-columns-paragraph *ngIf="paragraph.__typename == 'ParagraphColumn'" [paragraph]="paragraph" [withContainer]="withContainer"></app-columns-paragraph>
    <app-tile-paragraph *ngIf="paragraph.__typename == 'ParagraphTile'" [paragraph]="paragraph"></app-tile-paragraph>
    <app-library-paragraph *ngIf="paragraph.__typename == 'ParagraphFromLibrary'" [paragraph]="paragraph"></app-library-paragraph>
    <app-webform-paragraph *ngIf="paragraph.__typename == 'ParagraphWebform'" [paragraph]="paragraph" [withContainer]="withContainer"></app-webform-paragraph>
    <app-download-paragraph *ngIf="paragraph.__typename == 'ParagraphDownload'" [paragraph]="paragraph" [withContainer]="withContainer"></app-download-paragraph>
    <app-embed-paragraph *ngIf="paragraph.__typename == 'ParagraphEmbed'" [paragraph]="paragraph" [withContainer]="withContainer"></app-embed-paragraph>
    <app-call-to-action-paragraph *ngIf="paragraph.__typename == 'ParagraphCallToAction'" [paragraph]="paragraph" [withContainer]="withContainer"></app-call-to-action-paragraph>
    <app-overview-paragraph *ngIf="paragraph.__typename == 'ParagraphOverview'" [paragraph]="paragraph" [withContainer]="withContainer"></app-overview-paragraph>
    <app-menu-paragraph *ngIf="paragraph.__typename == 'ParagraphMenu'" [paragraph]="paragraph" [withContainer]="withContainer"></app-menu-paragraph>
    <app-tabs-paragraph *ngIf="paragraph.__typename == 'ParagraphTab'" [paragraph]="paragraph" [withContainer]="withContainer"></app-tabs-paragraph>
    <app-button-menu-paragraph *ngIf="paragraph.__typename == 'ParagraphButtonMenu'" [paragraph]="paragraph" [withContainer]="withContainer"></app-button-menu-paragraph>
    <app-accordion-paragraph *ngIf="paragraph.__typename == 'ParagraphAccordion'" [paragraph]="paragraph" [withContainer]="withContainer"></app-accordion-paragraph>
    <app-video-paragraph *ngIf="paragraph.__typename == 'ParagraphVideo'" [paragraph]="paragraph" [withContainer]="withContainer"></app-video-paragraph>
  </div>
</ng-container>
