import {Component, Input} from '@angular/core';
import {DatePipe, NgForOf, NgIf} from "@angular/common";

@Component({
  selector: 'app-event-date',
  standalone: true,
  imports: [
    NgIf,
    NgForOf,
    DatePipe
  ],
  templateUrl: './event-date.component.html',
  styleUrl: './event-date.component.scss'
})
export class EventDateComponent {

  @Input() dateRange!: {
    start: {
      timestamp: number
    }
    end: {
      timestamp: number
    }
  } | undefined;

  @Input() dates!: [{
    timestamp: number
  }] | undefined;

}
