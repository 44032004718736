import {Component, Input, OnInit} from '@angular/core';
import {BreadcrumbsComponent} from "../../breadcrumbs/breadcrumbs.component";
import {DatePipe, NgClass, NgIf} from "@angular/common";
import {NewsletterBlockComponent} from "../../newsletter-block/newsletter-block.component";
import {ParagraphsComponent} from "../../paragraphs/paragraphs.component";
import {ShareLinksComponent} from "../../../share-links/share-links.component";
import {BreadCrumb, DrupalNode} from "../../../models/node.model";
import {HeaderEntityComponent} from "../../header-entity/header-entity.component";

@Component({
  selector: 'app-interview-node',
  standalone: true,
    imports: [
        BreadcrumbsComponent,
        DatePipe,
        NewsletterBlockComponent,
        NgIf,
        ParagraphsComponent,
        ShareLinksComponent,
        NgClass,
        HeaderEntityComponent
    ],
  templateUrl: './interview-node.component.html',
  styleUrl: './interview-node.component.scss'
})
export class InterviewNodeComponent implements OnInit{
  @Input() node!: DrupalNode;
  @Input() breadcrumbs!: Array<BreadCrumb>;

  interviewMetaString = '';

  ngOnInit() {
    const interviewMetaArray = [];
    this.node.interviewName ? interviewMetaArray.push(this.node.interviewName):null;
    this.node.interviewSchool ? interviewMetaArray.push(this.node.interviewSchool):null;
    this.node.interviewLocation ? interviewMetaArray.push(this.node.interviewLocation):null;
    console.log('interviewMetaArray', interviewMetaArray);
    this.interviewMetaString = interviewMetaArray.join(', ');
  }

}
