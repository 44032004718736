import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {NgIf} from "@angular/common";
import {HeaderEntityComponent} from "../header-entity/header-entity.component";
import {BreadCrumb, DrupalNode} from "../../models/node.model";
import {ParagraphsComponent} from "../paragraphs/paragraphs.component";
import {NewsNodeComponent} from "./news-node/news-node.component";
import {OverviewNodeComponent} from "./overview-node/overview-node.component";
import {PageNodeComponent} from "./page-node/page-node.component";
import {BreadcrumbsComponent} from "../breadcrumbs/breadcrumbs.component";
import {Subscription} from "rxjs";
import {MetatagService} from "../../services/metatag.service";
import {InterviewNodeComponent} from "./interview-node/interview-node.component";
import {ThemeNodeComponent} from "./theme-node/theme-node.component";
import {DrupalMedia} from "../../models/media.model";
import {DocumentMediaComponent} from "./document-media/document-media.component";
import {EventNodeComponent} from "./event-node/event-node.component";

@Component({
  selector: 'app-drupal-entity',
  standalone: true,
  imports: [
    NgIf,
    HeaderEntityComponent,
    ParagraphsComponent,
    NewsNodeComponent,
    OverviewNodeComponent,
    PageNodeComponent,
    BreadcrumbsComponent,
    InterviewNodeComponent,
    ThemeNodeComponent,
    DocumentMediaComponent,
    EventNodeComponent
  ],
  templateUrl: './drupal-entity.component.html',
  styleUrl: './drupal-entity.component.scss'
})
export class DrupalEntityComponent implements OnInit {

  node: DrupalNode | any;
  media: DrupalMedia | any;
  breadcrumbs: Array<BreadCrumb>|any= [];
  subscription = new Subscription();

  constructor(private activatedRoute: ActivatedRoute, private metaTagService: MetatagService) {
  }

  ngOnInit() {
    //this.node = this.activatedRoute.data.pipe(data => data['node'])
    const sub = this.activatedRoute.data.subscribe(data => {
      console.log('new data', data['data']);

      if (data['data']) {
        // Set breadcrumbs
        if (data['data']['breadcrumbs']) {
          this.breadcrumbs = data['data']['breadcrumbs'];
        }
        if (data['data']['entity']) {
          if (data['data']['entity']['__typename'] == 'MediaDocument') {
            this.media = data['data']['entity'];
          } else {
            this.node = data['data']['entity'];
            if (this.node.__typename == 'NodeNews') {
              //this.breadcrumbs.push({title: 'Nieuws', url: '/#'})
            }
            if (this.node.metatag) {
              this.metaTagService.setMetatags(this.node.metatag);
            }
          }
        }
      }

    });
    this.subscription.add(sub);
  }
}
