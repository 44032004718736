import {Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {VideoParagraph} from "../../../models/node.model";

import {AsyncPipe, isPlatformServer, NgClass, NgIf} from "@angular/common";
import {BypassSecurity} from "../../../pipes/bypass-security.pipe";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {ReplaySubject, Subscription} from "rxjs";

@Component({
  selector: 'app-video-paragraph',
  standalone: true,
  imports: [
    BypassSecurity,
    NgIf,
    AsyncPipe,
    NgClass
  ],
  templateUrl: './video-paragraph.component.html',
  styleUrl: './video-paragraph.component.scss'
})
export class VideoParagraphComponent implements OnInit, OnDestroy{
  @Input() paragraph!:VideoParagraph;
  @Input() withContainer: boolean = false;

  private isDoneLoadingSubject$ = new ReplaySubject<boolean>();
  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();
  subscription = new Subscription();

  embedCode = '';

  oembedUrl = '';

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private http: HttpClient) {
  }

  ngOnInit() {
    //console.log('videoParagraph.paragraph', this.paragraph)
    if(this.paragraph.video.__typename == 'MediaVideo') {
      const sub = this.http.get(environment.drupalUrl + '/api/custom-oembed?url=' + this.paragraph.video.mediaOembedVideo, {responseType: 'text'}).subscribe(result => {
        this.embedCode = result;
        this.isDoneLoadingSubject$.next(true);
      });
      this.subscription.add(sub);
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
