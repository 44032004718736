import {Component, Input} from '@angular/core';
import {ButtonMenuParagraph} from "../../../models/node.model";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {RouterLink, RouterLinkActive} from "@angular/router";

@Component({
  selector: 'app-button-menu-paragraph',
  standalone: true,
  imports: [
    NgIf,
    NgForOf,
    RouterLink,
    RouterLinkActive,
    NgClass
  ],
  templateUrl: './button-menu-paragraph.component.html',
  styleUrl: './button-menu-paragraph.component.scss'
})
export class ButtonMenuParagraphComponent {

  @Input() paragraph!: ButtonMenuParagraph;
  @Input() withContainer: boolean = false;
}
