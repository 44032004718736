import {inject, PLATFORM_ID} from '@angular/core';
import {
  Router, RouterStateSnapshot,
  ActivatedRouteSnapshot, ResolveFn
} from '@angular/router';
import {isPlatformBrowser, Location} from '@angular/common';
import {catchError, map} from 'rxjs/operators';

import {GraphQLService} from './graph-ql.service';

import {Observable, of} from "rxjs";

export const NodeResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  graphQLService: GraphQLService = inject(GraphQLService),
): Observable<any> => {

  const router = inject(Router);
  const location = inject(Location);
  const platformId =  inject(PLATFORM_ID);// private platformId

  const urlTree = router.parseUrl(state.url);
  urlTree.queryParams = {};
  urlTree.fragment = null;
  const plainUrl = urlTree.toString();

  return graphQLService.getNode(state.url).pipe(
    map(nodeResult => {

      if (nodeResult.data?.route) {
        // TODO: This should probably return the proper status code for SEO purposes.
        if(nodeResult.data?.route.__typename == 'RouteRedirect') {
          //response.status(nodeResult.data?.route.status);
          router.navigateByUrl(nodeResult.data?.route.url);
          return null;
        } else {
          return {
            'entity': nodeResult.data.route.entity,
            'breadcrumbs': nodeResult.data.route.breadcrumbs
          };
        }
      } else {
        if (!isPlatformBrowser(platformId)) {
          //res.status(404);
        }
        const notFoundParams = state.url.replace(/[^a-zA-Z0-9 ]/g, ' ').trim();

        router.navigateByUrl('/zoeken?local_ggn_algolia[query]='+notFoundParams);
        return null;
      }
    }),
    catchError((err, caught) => {
      /*if (!isPlatformBrowser(this.platformId)) {
          this.res.status(404);
      }*/
      //this.debug.log('error', err);
      //this.debug.log('caught', caught);
      if (!isPlatformBrowser(platformId)) {
        //res.status(404);
      }

      return of(null);
    })
  )
}

