<a [routerLink]="cardData.path" class="focus:outline outline-offset-2 rounded-t-2xl flex flex-col group">
  <div class="block h-[270px] w-full rounded-t-2xl overflow-hidden bg-tertiary-default relative">
    <div class="absolute px-8" *ngIf="cardData.education || cardData.subject">
      <ng-container *ngIf="cardData.education">
        <span
          class="bg-white py-2 px-5 text-primary mr-3 rounded-3xl mt-5 inline-block">{{ cardData.education.name }}</span>
      </ng-container>
      <ng-container *ngIf="cardData.subject">
        <span class="bg-white py-2 px-5 text-primary rounded-3xl mt-5 inline-block">{{ cardData.subject.name }}</span>
      </ng-container>
    </div>
    <img src="{{imgUrl}}" class="object-cover h-full w-full" *ngIf="imgUrl">
    <span *ngIf="!imgUrl" class="flex items-center h-full w-full p-4">
      <span class="block text-center w-full font-bold text-6xl">
      <ng-container *ngIf="cardData.eventDate">
        {{cardData.eventDate[0].timestamp * 1000 | date: 'd MMMM'}}
      </ng-container>
      <ng-container *ngIf="!cardData.eventDate && cardData.eventDateRange">
        {{cardData.eventDateRange.start.timestamp * 1000 | date: 'd MMM'}}
      </ng-container>
        </span>
    </span>
  </div>
  <div class="border border-tertiary-default p-8 flex-1 flex flex-col">
    <ng-container [ngTemplateOutlet]="cardContent"></ng-container>
  </div>
</a><!--
<ng-template #highlightedTpl>
  <a [routerLink]="cardData.path" class="relative block group w-full group">
    <div class="relative">
      <div class="absolute px-8" *ngIf="cardData.education || cardData.subject">
        <ng-container *ngIf="cardData.education">
          <span class="bg-white py-2 px-5 text-primary mr-3 rounded-3xl mt-5 inline-block">{{cardData.education.name}}</span>
        </ng-container>
        <ng-container *ngIf="cardData.subject">
          <span class="bg-white py-2 px-5 text-primary rounded-3xl mt-5 inline-block">{{cardData.subject.name}}</span>
        </ng-container>
      </div>
      <img src="{{imgUrl}}"  class="object-cover h-full w-full rounded-t-2xl" *ngIf="imgUrl">

    </div>
    <div class="bg-secondary p-8 absolute bottom-0 right-0 w-1/2  rounded-tl-2xl">
      <ng-container [ngTemplateOutlet]="cardContent"></ng-container>
    </div>
  </a>
</ng-template>-->

<ng-template #cardContent>
  <div class="flex justify-between text-sm mb-4">
    <app-event-date [dateRange]="cardData.eventDateRange" [dates]="cardData.eventDate"></app-event-date>
    <span *ngIf="cardData.location" class="font-bold flex items-center"><svg class="mr-1" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="!cardData.onlineEvent">
  <path d="M12 12C12.55 12 13.0208 11.8042 13.4125 11.4125C13.8042 11.0208 14 10.55 14 10C14 9.45 13.8042 8.97917 13.4125 8.5875C13.0208 8.19583 12.55 8 12 8C11.45 8 10.9792 8.19583 10.5875 8.5875C10.1958 8.97917 10 9.45 10 10C10 10.55 10.1958 11.0208 10.5875 11.4125C10.9792 11.8042 11.45 12 12 12ZM12 19.35C14.0333 17.4833 15.5417 15.7875 16.525 14.2625C17.5083 12.7375 18 11.3833 18 10.2C18 8.38333 17.4208 6.89583 16.2625 5.7375C15.1042 4.57917 13.6833 4 12 4C10.3167 4 8.89583 4.57917 7.7375 5.7375C6.57917 6.89583 6 8.38333 6 10.2C6 11.3833 6.49167 12.7375 7.475 14.2625C8.45833 15.7875 9.96667 17.4833 12 19.35ZM12 22C9.31667 19.7167 7.3125 17.5958 5.9875 15.6375C4.6625 13.6792 4 11.8667 4 10.2C4 7.7 4.80417 5.70833 6.4125 4.225C8.02083 2.74167 9.88333 2 12 2C14.1167 2 15.9792 2.74167 17.5875 4.225C19.1958 5.70833 20 7.7 20 10.2C20 11.8667 19.3375 13.6792 18.0125 15.6375C16.6875 17.5958 14.6833 19.7167 12 22Z" fill="#F8D100"/>
</svg>
      <svg class="mr-1" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="cardData.onlineEvent">
<path d="M1 21V19H23V21H1ZM4 18C3.45 18 2.97917 17.8042 2.5875 17.4125C2.19583 17.0208 2 16.55 2 16V5C2 4.45 2.19583 3.97917 2.5875 3.5875C2.97917 3.19583 3.45 3 4 3H20C20.55 3 21.0208 3.19583 21.4125 3.5875C21.8042 3.97917 22 4.45 22 5V16C22 16.55 21.8042 17.0208 21.4125 17.4125C21.0208 17.8042 20.55 18 20 18H4ZM4 16H20V5H4V16Z" fill="#F8D100"/>
</svg>{{cardData.location}}</span>
  </div>
  <h2>{{ cardData.title }}</h2>
  <div [innerHTML]="cardData.body.processed | stripTags" *ngIf="cardData.body" class="mb-6"></div>
  <span class="flex items-center font-bold text-lg mt-auto group-hover:underline group-focus:outline">
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-4">
              <path
                d="M24 16L21.2 18.8L24.4 22L16 22L16 26L24.4 26L21.2 29.2L24 32L32 24L24 16ZM24 4C26.7667 4 29.3667 4.525 31.8 5.575C34.2333 6.625 36.35 8.05 38.15 9.85C39.95 11.65 41.375 13.7667 42.425 16.2C43.475 18.6333 44 21.2333 44 24C44 26.7667 43.475 29.3667 42.425 31.8C41.375 34.2333 39.95 36.35 38.15 38.15C36.35 39.95 34.2333 41.375 31.8 42.425C29.3667 43.475 26.7667 44 24 44C21.2333 44 18.6333 43.475 16.2 42.425C13.7667 41.375 11.65 39.95 9.85 38.15C8.05 36.35 6.625 34.2333 5.575 31.8C4.525 29.3667 4 26.7667 4 24C4 21.2333 4.525 18.6333 5.575 16.2C6.625 13.7667 8.05 11.65 9.85 9.85C11.65 8.05 13.7667 6.625 16.2 5.575C18.6333 4.525 21.2333 4 24 4ZM24 8C19.5333 8 15.75 9.55 12.65 12.65C9.55 15.75 8 19.5333 8 24C8 28.4667 9.55 32.25 12.65 35.35C15.75 38.45 19.5333 40 24 40C28.4667 40 32.25 38.45 35.35 35.35C38.45 32.25 40 28.4667 40 24C40 19.5333 38.45 15.75 35.35 12.65C32.25 9.55 28.4667 8 24 8Z"
                fill="#481249"/>
            </svg>
      <span class="group-hover:underline">Lees meer</span>
          </span>
</ng-template>
