<a [routerLink]="cardData.path" class="card w-full focus:outline outline-offset-2 rounded-t-2xl flex flex-col group h-full" *ngIf="!highlighted else highlightedTpl">
  <div class="block h-[270px] w-full rounded-t-2xl overflow-hidden bg-[#D9D9D9] relative">
    <div class="absolute px-8" *ngIf="cardData.education || cardData.subject">
      <ng-container *ngIf="cardData.education">
        <span class="bg-white py-2 px-5 text-primary mr-3 rounded-3xl mt-5 inline-block">{{cardData.education.name}}</span>
      </ng-container>
      <ng-container *ngIf="cardData.subject">
        <span class="bg-white py-2 px-5 text-primary rounded-3xl mt-5 inline-block">{{cardData.subject.name}}</span>
      </ng-container>
    </div>
    <img src="{{imgUrl}}" class="card-image object-cover h-full w-full" *ngIf="imgUrl">
  </div>
  <div class="border border-tertiary-default p-8 flex-1 flex flex-col h-full">
    <ng-container [ngTemplateOutlet]="cardContent"></ng-container>
  </div>
</a>


<ng-template #highlightedTpl>
  <a [routerLink]="cardData.path" class="relative block group w-full">
    <div class="relative">
      <div class="absolute px-8" *ngIf="cardData.education || cardData.subject">
        <ng-container *ngIf="cardData.education">
          <span class="bg-white py-2 px-5 text-primary mr-3 rounded-3xl mt-5 inline-block">{{cardData.education.name}}</span>
        </ng-container>
        <ng-container *ngIf="cardData.subject">
          <span class="bg-white py-2 px-5 text-primary rounded-3xl mt-5 inline-block">{{cardData.subject.name}}</span>
        </ng-container>
      </div>
      <img src="{{imgUrl}}" class="card-image object-cover h-full w-full rounded-t-2xl" *ngIf="imgUrl">
    </div>
    <div class="highlighted-info bg-secondary p-8 absolute bottom-0 right-0 w-1/2 rounded-tl-2xl">
      <ng-container [ngTemplateOutlet]="cardContent"></ng-container>
    </div>
  </a>
</ng-template>

<ng-template #cardContent>
  <div class="flex justify-between text-sm mb-4">
    <app-read-time *ngIf="cardData.readTime" [readTime]="cardData.readTime"></app-read-time>
    {{cardData.created?.time | date: 'd MMMM y'}}
  </div>
  <h2>{{ cardData.title }}</h2>
  <div *ngIf="shortenedText" [innerHTML]="shortenedText | stripTags" class="mb-6 mt-4"></div>
  <span class="flex items-center font-bold text-lg mt-auto group-hover:underline group-focus:outline">
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-4">
      <path d="M24 16L21.2 18.8L24.4 22L16 22L16 26L24.4 26L21.2 29.2L24 32L32 24L24 16ZM24 4C26.7667 4 29.3667 4.525 31.8 5.575C34.2333 6.625 36.35 8.05 38.15 9.85C39.95 11.65 41.375 13.7667 42.425 16.2C43.475 18.6333 44 21.2333 44 24C44 26.7667 43.475 29.3667 42.425 31.8C41.375 34.2333 39.95 36.35 38.15 38.15C36.35 39.95 34.2333 41.375 31.8 42.425C29.3667 43.475 26.7667 44 24 44C21.2333 44 18.6333 43.475 16.2 42.425C13.7667 41.375 11.65 39.95 9.85 38.15C8.05 36.35 6.625 34.2333 5.575 31.8C4.525 29.3667 4 26.7667 4 24C4 21.2333 4.525 18.6333 5.575 16.2C6.625 13.7667 8.05 11.65 9.85 9.85C11.65 8.05 13.7667 6.625 16.2 5.575C18.6333 4.525 21.2333 4 24 4ZM24 8C19.5333 8 15.75 9.55 12.65 12.65C9.55 15.75 8 19.5333 8 24C8 28.4667 9.55 32.25 12.65 35.35C15.75 38.45 19.5333 40 24 40C28.4667 40 32.25 38.45 35.35 35.35C38.45 32.25 40 28.4667 40 24C40 19.5333 38.45 15.75 35.35 12.65C32.25 9.55 28.4667 8 24 8Z" fill="#481249"/>
    </svg>
    <span class="group-hover:underline">Lees dit artikel</span>
  </span>
</ng-template>

