<div
  class="header mx-auto text-primary"
  [ngClass]="{'mb-2 lg:mb-20': !isHomePage}"
  *ngIf="!header.highlightedNode else highlightedHeader"
>
  <div class="container grid grid-cols-2">
    <div class="order-2 lg:order-1 col-span-2 lg:col-span-1 pt-8 lg:pt-32 lg:pr-16">
      <h1 class="text-4xl lg:text-7xl font-bold mb-4 lg:mb-8 break-words hyphens-auto">{{ header.title }}</h1>
      <div class="text-xl lg:text-2xl mb-4 lg:mb-8">
        <app-text-content [content]="header.intro?.processed" *ngIf="header.intro?.processed"></app-text-content>
      </div>
      <div class="inline-content header-txt">
        <app-text-content [content]="header.content?.processed" *ngIf="header.content?.processed"></app-text-content>
      </div>
    </div>
    <div class="order-1 lg:order-2 col-span-2 lg:col-span-1 image">
      <div class="image-container bg-[#481249]" *ngIf="maskedUrl">
        <img [src]="maskedUrl" alt="Gezonde school - afbeelding" class="generated-image rounded-xl h-full w-full">
      </div>
    </div>
  </div>
</div>
<ng-template #highlightedHeader>
  <div class="container mt-28 mb-4 lg:mb-24" [ngClass]="{'mb-4 lg:mb-20': !isHomePage}">
    <div class="grid grid-cols-6">
      <div class="col-span-6 lg:col-span-2 pr-16">
        <h1 class="text-4xl md:text-7xl font-bold mb-8 break-words hyphens-auto">{{ header.title }}</h1>
        <div class="header-intro text-xl md:text-2xl mb-8">
          <app-text-content [content]="header.intro?.processed"></app-text-content>
        </div>
        <div class="!text-xl !md:text-2xl !mb-8" style="all: unset;">
          <app-text-content [content]="header.content?.processed"></app-text-content>
        </div>
      </div>
      <div class="col-span-6 lg:col-span-4 image">
        <app-card-with-image
        [highlighted]="true"
        [cardData]="header.highlightedNode"
        [fallbackImageFromHeader]="header.image.mediaImage?.variations?.[0]?.url"
        *ngIf="header.highlightedNode">
      </app-card-with-image>
      </div>
    </div>
  </div>
</ng-template>
