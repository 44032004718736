import {Component, Inject, Input, PLATFORM_ID} from '@angular/core';
import {EmbedParagraph} from "../../../models/node.model";
import {isPlatformBrowser, NgClass, NgIf} from "@angular/common";
import {BypassSecurity} from "../../../pipes/bypass-security.pipe";
import {RunScriptsDirective} from "../../../../directives/run-scripts.directive";

declare var Atabase: any;
@Component({
  selector: 'app-embed-paragraph',
  standalone: true,
  imports: [
    NgIf,
    BypassSecurity,
    NgClass,
    RunScriptsDirective
  ],
  templateUrl: './embed-paragraph.component.html',
  styleUrl: './embed-paragraph.component.scss'
})
export class EmbedParagraphComponent {
  @Input() paragraph!: EmbedParagraph;
  @Input() withContainer = true;


  isBrowser = false;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    if (isPlatformBrowser(this.platformId)) {
      this.isBrowser = true;
    }
  }

  ngOnInit() {

  }

}
