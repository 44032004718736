import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {Router} from "@angular/router";
import {Meta, Title} from "@angular/platform-browser";
import {DOCUMENT} from "@angular/common";
import {LinkDefinition} from "../models/link-definition";
import {Subject} from "rxjs";
import {environment} from "../../environments/environment";
import {BreadCrumb, MetaTag} from "../models/node.model";

@Injectable({
  providedIn: 'root'
})
export class MetatagService {

  constructor(private meta: Meta,
              private titleService: Title,
              @Inject(DOCUMENT) private readonly document: Document,
              @Inject(LOCALE_ID) private locale: string,
              private router: Router) { }

  breadcrumbsMap = new Map<string, Array<BreadCrumb>>();

  private breadcrumbsChanged = new Subject();
  breadcrumbsChanged$ = this.breadcrumbsChanged.asObservable();
  breadcrumbs!: Array<BreadCrumb>;


  setMetatags(meta: [MetaTag], noSiteName?: boolean) {
    if (meta !== undefined) {

      // Unset all Meta tags to prevent them from leaking to different pages
      this.removeMetatags();
      this.removeLinkTag({rel: 'canonical'});

      // Set all new metatags.
      const newTags = meta;
      newTags.forEach(metatag => {
        if (metatag.__typename == 'MetaTagLink' && metatag.attributes && metatag.attributes.href && metatag.attributes.rel) {
          let href = '';
          href = metatag.attributes.href.replace(environment.drupalUrl, environment.frontendUrl);
          this.updateLinkTag({rel: metatag.attributes.rel, href});
        }
        if (metatag.__typename == 'MetaTagValue'  && metatag.attributes && metatag.attributes.content && metatag.attributes.name) {
            if (metatag.attributes.name == 'title') {
              if (noSiteName) {
                this.setTitleNoSiteName(metatag.attributes.content);
              } else {
                this.setTitle(metatag.attributes.content);
              }
            } else {
              if (this.meta.getTag('name=' + metatag.attributes.name)) {
                this.meta.updateTag({name: metatag.attributes.name, content: metatag.attributes.content});
              } else {
                this.meta.addTag({name: metatag.attributes.name, content: metatag.attributes.content});
              }
            }
          }
      })
    }
  }

  /**
   * Remove a link tag from DOM
   * @param  tag
   */
  public removeLinkTag(tag: LinkDefinition): void {
    const selector = this._parseSelector(tag);
    const linkElement = this.document.head.querySelector(selector) as HTMLLinkElement;

    if (linkElement) {
      this.document.head.removeChild(linkElement);
    }
  }

  /**
   * Create or update a link tag
   * @param  {LinkDefinition} tag
   */
  public updateLinkTag(tag: LinkDefinition): void {
    const selector = this._parseSelector(tag);
    const linkElement = this.document.head.querySelector(selector) as HTMLLinkElement
      || this.document.head.appendChild(this.document.createElement('link'));

    if (linkElement) {
      Object.keys(tag).forEach((prop: string) => {
        linkElement.setAttribute(prop, tag[prop]) ;
      });
    }
  }

  removeMetatags() {
    // Hacky.  Unset all existing metatags
    const currentTags = this.meta.getTags('name');
    const self = this;
    if (currentTags) {
      Object.keys(currentTags).forEach((value:string, key: number) => {
        if (currentTags[key].name !== 'viewport' && currentTags[key].name !== 'msapplication-TileColor' && currentTags[key].name !== 'theme-color' && currentTags[key].name !== 'apple-mobile-web-app-capable' && currentTags[key].name !== 'mobile-web-app-capable') {
          self.meta.removeTagElement(currentTags[key]);
        }
      });
    }
  }

  setTitleNoSiteName(title: string) {
    this.titleService.setTitle(title);
    this.meta.updateTag({name: 'og:title', content: title});
  }

  setTitle(title: string) {
    //title = title + ' | ' + environment.siteName;
    this.titleService.setTitle(title);
    this.meta.updateTag({name: 'og:title', content: title});
  }

  setDescription(description: string) {
    this.meta.updateTag({name: 'description', content: description});
  }

  /**
   * Parse tag to create a selector
   * @param tag
   * @return selector to use in querySelector
   */
  private _parseSelector(tag: LinkDefinition): string {
    const attr: string = tag.id ? 'id' : tag.rel ? 'rel' : 'hreflang';
    return `link[${attr}="${tag[attr]}"]`;
  }

  setBreadcrumbs(breadcrumbs: Array<BreadCrumb>) {
    if (!this.breadcrumbsMap.get(this.router.url)) {
      this.breadcrumbsMap.set(this.router.url, breadcrumbs);
    } else {
      // console.log('crumbs for path already set', this.router.url);
    }
    this.breadcrumbsChanged.next(true);
  }

  getBreadcrumbsArray(): Array<BreadCrumb> | undefined {
    return this.breadcrumbsMap.get(this.router.url);
  }


}
