import {Component, Input} from '@angular/core';
import {ReadTime} from "../../models/node.model";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-read-time',
  standalone: true,
  imports: [
    NgIf
  ],
  templateUrl: './read-time.component.html',
  styleUrl: './read-time.component.scss'
})
export class ReadTimeComponent {
  @Input() readTime!: ReadTime;
}
