<div class="toc-wrapper" [class.sticky]="sticky" #tableOfContentsWrapper>
  <div class="table-of-contents border border-tertiary-default rounded-2xl py-5 px-7 lg:py-10 lg:px-14">
    <div class="">
      <h2 class="mb-2">Op deze pagina:</h2>
      <div id="main-table-of-contents" class="toc">
        <div class="toc-item-wrapper" *ngFor="let heading of headings">
          <button class="toc--item text-left text-tertiary-dark underline hover:no-underline focus:outline" (click)="scrollTo(heading.target)"
                  title="Scroll naar {{heading.title}}" attr.aria-label="Scroll naar {{heading.title}}" *ngIf="heading.title">
            <span class="icon-arrow"></span>
            <span aria-hidden="true" class="heading-title">{{heading.title}}</span>
          </button>
          <div class="toc-children" *ngIf="heading.children.length > 0">
            <button class="toc--item" (click)="scrollTo(child.target)"
                    title="Scroll naar {{child.title}}" attr.aria-label="Scroll naar {{child.title}}" *ngFor="let child of heading.children">
              <span class="icon-arrow"></span>
              <span aria-hidden="true" class="heading-title">{{child.title}}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--<div class="mobile d-block d-xl-none">
      <div class="accordion" id="accordion-long-content-nav">
        <div class="accordion-item">
          <div class="accordion-item&#45;&#45;header" id="header-long-content-nav">
            <h2 class="toc&#45;&#45;title">
              <button class="accordion-toggle collapsed" data-toggle="collapse"
                      [attr.data-target]="'#collapse-long-content-nav'" aria-expanded="false"
                      [attr.aria-controls]="'collapse-long-content-nav'">
                Inhoudsopgave
                <span class="vggm-icon"></span>
              </button>
            </h2>
          </div>
          <div [attr.data-parent]="'#accordion-long-content-nav'" class="accordion-item&#45;&#45;body collapse"
               [attr.aria-labelledby]="'header-long-content-nav'" id="collapse-long-content-nav">
            <div class="table-of-contents">
              <div id="main-table-of-contents-mobile" class="toc">
                <div class="toc-item-wrapper" *ngFor="let heading of headings">
                  <button class="toc&#45;&#45;item" (click)="scrollTo(heading.title, true)"
                          title="Scroll naar {{heading.title}}">
                    <span class="icon-arrow"></span>
                    <span aria-hidden="true" class="heading-title">{{heading.title}}</span>
                  </button>
                  <div class="toc-children" *ngIf="heading.children.length > 0">
                    <button class="toc&#45;&#45;item" (click)="scrollTo(child, true)"
                            title="Scroll naar {{child}}" *ngFor="let child of heading.children">
                      <span class="icon-arrow"></span>
                      <span aria-hidden="true" class="heading-title">{{child}}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>-->
  </div>
  <div class="fill-space" [class.show]="fixedMobile" [ngStyle]="fillSpaceStyle"></div>
</div>
