import {afterNextRender, Component, Inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import {TabsParagraph} from "../../../models/node.model";
import {isPlatformBrowser, NgClass, NgForOf, NgIf} from "@angular/common";
import {TabsItemParagraphComponent} from "../tabs-item-paragraph/tabs-item-paragraph.component";
import {FlowbiteService} from "../../../services/flowbite.service";
import {TabViewModule} from "primeng/tabview";

@Component({
  selector: 'app-tabs-paragraph',
  standalone: true,
  imports: [
    NgIf,
    TabsItemParagraphComponent,
    NgForOf,
    NgClass,
    TabViewModule
  ],
  templateUrl: './tabs-paragraph.component.html',
  styleUrl: './tabs-paragraph.component.scss'
})
export class TabsParagraphComponent implements OnInit{
  @Input() paragraph!: TabsParagraph;
  @Input() withContainer: boolean = false;

  isBrowser;

  constructor(private flowbiteService: FlowbiteService, @Inject(PLATFORM_ID) private platformId: Object) {


    afterNextRender(() => {
    });
    if (isPlatformBrowser(this.platformId)) {
      this.isBrowser = false;
    }
  }

  ngOnInit() {

  }

}
