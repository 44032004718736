<div class="paragraph--video my-10" [ngClass]="{'container mx-auto': withContainer}">
  <div class="video">
    <div class="video--embed" [innerHTML]="embedCode | bypassSecurity: 'html'"
         *ngIf="embedCode  else placeholder">
    </div>
    <div class="container-narrow" *ngIf="paragraph.caption">
      <div class="video--caption" [innerHTML]="paragraph.caption.processed">
      </div>
    </div>
  </div>
  <ng-template #placeholder>
    <span *ngIf="isDoneLoading$ | async; else loading">
      <p>De embed kon niet worden geladen.</p>
    </span>
    <ng-template #loading>
      <p>De embed wordt geladen.</p>
    </ng-template>
  </ng-template>
</div>
