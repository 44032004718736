import { Component, Input, OnInit } from '@angular/core';
import { AccordionItemParagraph, AccordionParagraph } from "../../../models/node.model";
import { NgClass, NgForOf, NgIf } from "@angular/common";
import { AccordionModule } from "primeng/accordion";
import { Router } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-accordion-paragraph',
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    NgForOf,
    AccordionModule,
  ],
  templateUrl: './accordion-paragraph.component.html',
  styleUrls: ['./accordion-paragraph.component.scss']
})
export class AccordionParagraphComponent implements OnInit {
  @Input() paragraph!: AccordionParagraph;
  @Input() withContainer: boolean = false;
  isHomePage: boolean = false;
  isExpanded: boolean = false;
  accordionItems!: Array<AccordionItemParagraph>;
  hasCollapsibleItems: boolean = false;

  accordionItemsWithTruncation!: Array<{
    accordionItem: AccordionItemParagraph,
    isTruncated: boolean,
    truncatedContent: SafeHtml,
    isExpanded: boolean
  }>;

  constructor(private router: Router, private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.isHomePage = this.router.url === '/';
    this.accordionItems = this.paragraph.accordionItems;
    this.hasCollapsibleItems = this.accordionItems.some(item => item.collapsible);

    this.accordionItemsWithTruncation = this.paragraph.accordionItems.map((item: AccordionItemParagraph) => {
      const title = item.title || '';
      const fullContent = item.content?.processed || '';
      const combinedContent = `${title} ${fullContent}`;
      const isTruncated = combinedContent.length > 650;
      const truncatedContent = isTruncated ? this.sanitizeHtml(this.truncateHtml(fullContent, 650) + '...') : this.sanitizeHtml(fullContent);
      const isExpanded = this.isHomePage ? false : !item.collapsible;

      return {
        accordionItem: item,
        isExpanded,
        truncatedContent,
        isTruncated
      };
    });
  }

  /**
   * Sanitize HTML content to safely render HTML tags.
   */
  sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  /**
   * Truncate HTML content but retain allowed tags like <ul>, <li>, <h2>, <h3>, and <h4>.
   */
  truncateHtml(html: string, maxLength: number): string {
    let strippedHtml = html.replace(/<(?!\/?(ul|li|h2|h3|h4|a)\b)[^>]*>/gi, "");

    if (strippedHtml.length > maxLength) {
      let truncatedText = strippedHtml.substring(0, maxLength);
      const lastSpaceIndex = truncatedText.lastIndexOf(" ");
      if (lastSpaceIndex > 0) {
        truncatedText = truncatedText.substring(0, lastSpaceIndex);
      }
      if (truncatedText.lastIndexOf("<a>") > truncatedText.lastIndexOf("</a>")) {
        truncatedText += "</a>";
      }
      if (truncatedText.lastIndexOf("<li>") > truncatedText.lastIndexOf("</li>")) {
        truncatedText += "</li>";
      }
      if (truncatedText.lastIndexOf("<ol>") > truncatedText.lastIndexOf("</ol>")) {
        truncatedText += "</ol>";
      }
      if (truncatedText.lastIndexOf("<ul>") > truncatedText.lastIndexOf("</ul>")) {
        truncatedText += "</ul>";
      }
      if (truncatedText.lastIndexOf("<h2>") > truncatedText.lastIndexOf("</h2>")) {
        truncatedText += "</h2>";
      }
      if (truncatedText.lastIndexOf("<h3>") > truncatedText.lastIndexOf("</h3>")) {
        truncatedText += "</h3>";
      }
      if (truncatedText.lastIndexOf("<h4>") > truncatedText.lastIndexOf("</h4>")) {
        truncatedText += "</h4>";
      }
      if (truncatedText.lastIndexOf("<h5>") > truncatedText.lastIndexOf("</h5>")) {
        truncatedText += "</h5>";
      }
      if (truncatedText.lastIndexOf("<h6>") > truncatedText.lastIndexOf("</h6>")) {
        truncatedText += "</h6>";
      }

      return truncatedText;
    }

    return strippedHtml;
  }

  toggleExpand(accordionItemWithTruncation: any) {
    accordionItemWithTruncation.isExpanded = !accordionItemWithTruncation.isExpanded;
  }
}