import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ColumnsParagraph} from "../../../models/node.model";
import {ParagraphsComponent} from "../paragraphs.component";
import {NgClass, NgForOf, NgIf} from "@angular/common";

@Component({
  selector: 'app-columns-paragraph',
  standalone: true,
  imports: [

    forwardRef(() => ParagraphsComponent),
    NgForOf,
    NgClass,
    NgIf
  ],
  templateUrl: './columns-paragraph.component.html',
  styleUrl: './columns-paragraph.component.scss'
})
export class ColumnsParagraphComponent implements OnInit{
  @Input() paragraph!: ColumnsParagraph;
  @Input() withContainer:boolean=false;

  columnClasses: string = '';
  bgClass: string = '';
  titleColorClass: string = '';

  isWhiteText: boolean = false; 

  ngOnInit() {
    console.log('columnsParagraph', this.paragraph)
    if(this.paragraph.color?.color) {
      this.bgClass = 'bg-['+this.paragraph.color.color+']'
      if(this.paragraph.color.color == '#481249') {
        this.titleColorClass = 'text-white';
      }
      console.log('this.bgClass', this.bgClass);
    }
    switch (this.paragraph.columns){
      case 4:
        this.columnClasses = 'md:col-span-6 lg:col-span-6 xl:col-span-3';
        break;
      case 3:
        this.columnClasses = 'md:col-span-6 lg:col-span-4';
        break;
      case 2:
        this.columnClasses = 'md:col-span-6';
    }
    this.paragraph.type

    if (this.paragraph.color?.color === '#481249') {
      this.isWhiteText = true;
    }
  }

}
