import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { QuoteParagraph } from '../../../models/node.model';
import { NgClass, NgIf } from '@angular/common';

@Component({
  selector: 'app-quote-paragraph',
  standalone: true,
  imports: [NgIf, NgClass],
  templateUrl: './quote-paragraph.component.html',
  styleUrls: ['./quote-paragraph.component.scss'],
})
export class QuoteParagraphComponent implements OnChanges {
  @Input() paragraph!: QuoteParagraph;
  @Input() withContainer = true;

  paragraphCopy!: QuoteParagraph;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['paragraph'] && changes['paragraph'].currentValue) {
      const paragraphData = changes['paragraph'].currentValue as QuoteParagraph;
      // Clone while preserving hideIcon, and only set default to false if undefined or null
      this.paragraphCopy = {
        ...paragraphData,
        hideIcon: paragraphData.hideIcon ?? false, // Set to false only if undefined or null
      };

    }
  }  
}
