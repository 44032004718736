<app-header-entity [header]="node.header" *ngIf="node.header"></app-header-entity>
<div class="container mx-auto w-full" [ngClass]="{'no-aside': !node.sidebarParagraphs && !node.showToc, 'aside': node.sidebarParagraphs || node.showToc}">
  <div class="pt-9 pb-14">
    <app-breadcrumbs [breadcrumbs]="breadcrumbs" *ngIf="breadcrumbs"></app-breadcrumbs>
  </div>
  <div class="" [ngClass]="{'lg:grid lg:grid-cols-3 lg:gap-x-10': !node.sidebarParagraphs && !node.showToc}">
    <ng-container *ngIf="node.showToc || node.sidebarParagraphs else noSidebar">
      <div class="lg:grid lg:grid-cols-3 lg:gap-x-10">
        <div class="lg:col-span-1" *ngIf="node.sidebarParagraphs">
          <app-paragraphs [paragraphs]="node.sidebarParagraphs"></app-paragraphs>
        </div>
        <div class="lg:col-span-2 mx-auto">
          <span class="pb-8 block">
            <span class="block pb-3 text-sm">Publicatiedatum: {{ node.created.time|date: 'd MMMM y' }}</span>
              <h1 class="font-bold text-3xl">{{ node.title }}</h1>
              <p class="text-3xl tracking-tighter font-light" *ngIf="node.subtitle">{{node.subtitle}}</p>
            </span>
            <img *ngIf="node.image && node.image.mediaImage" src="{{node.image.mediaImage.url}}" alt="{{node.image.mediaImage.alt}}" loading="lazy" class="rounded-lg mb-10">
            <div class="text-1xl lg:text-2xl font-bold leading-normal intro" *ngIf="node.body" [innerHTML]="node.body.processed"></div>
          </div>
        <div class="lg:col-span-1" *ngIf="node.showToc && node.paragraphs">
          <app-table-of-contents [content]="node.paragraphs" *ngIf="node.paragraphs"></app-table-of-contents>
        </div>
      </div>
      <app-paragraphs [paragraphs]="node.paragraphs" *ngIf="node.paragraphs" [withContainer]="false"></app-paragraphs>
    </ng-container>
    <ng-template #noSidebar>

      <div class="lg:col-span-3 mx-auto">
        <span class="pb-8 block">
          <span class="block pb-3 text-sm">Publicatiedatum: {{ node.created.time|date: 'd MMMM y' }}</span>
            <h1 class="font-bold text-3xl">{{ node.title }}</h1>
            <p class="text-3xl tracking-tighter font-light" *ngIf="node.subtitle">{{node.subtitle}}</p>
          </span>
        <img *ngIf="node.image && node.image.mediaImage" src="{{node.image.mediaImage.url}}" alt="{{node.image.mediaImage.alt}}" loading="lazy" class="rounded-lg mb-10">
        <div class="text-xl font-bold leading-normal intro" *ngIf="node.body" [innerHTML]="node.body.processed"></div>
      <span class="node--paragraphs">
        <app-paragraphs [paragraphs]="node.paragraphs" *ngIf="node.paragraphs" [withContainer]="false"></app-paragraphs>
      </span>
      </div>
    </ng-template>

  </div>
  <app-share-links></app-share-links>
</div>

<div class="container mx-auto mt-4 lg:mt-24">
  <h2 class="inline-flex items-center text-3xl md:text-7xl font-bold"><svg width="53" height="52" viewBox="0 0 53 52" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-6">
    <path d="M52.7622 24.896L44.0213 2.33909C44.0213 2.33909 43.4062 0 41.7151 0L30.5565 0.00273273L2.06026 0.00959245C0.926366 0.00959245 0.0074013 1.08954 0.0074013 2.42214L0.00555214 14.8808L0.00184917 37.1306L0 49.5816C0 50.9141 0.919154 51.9941 2.05291 51.9941L30.5552 51.9982L41.7151 52C43.3548 52 44.0993 49.6577 44.0993 49.6577L52.7522 27.3213C52.7522 27.3213 53 26.6989 53 26.0946C53 25.4792 52.7622 24.896 52.7622 24.896Z" fill="#F8D100"/>
  </svg> Nieuws & Inspiratie</h2>
</div>
<app-news-overview [limit]="3"></app-news-overview>

